/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
// component for Low Power
import React from "react";
import Select from "react-select";
import ReactTooltip from "react-tooltip";
import helper from "../../../helper/helper";
import unchecked from "../../../assets/images/white.png";
import checked from "../../../assets/images/squre-round.png";
import { CALLING_LOWPOWER_TOOLTIP, BLOCKING_LOWPOWER_TOOLTIP, ZONE } from "../../../services/Constant";

const LowPowerComponent = ({
  systemType,
  setBlockingLowPower,
  setCallingLowPower,
  enableLowPower,
  setBlockingIntensity,
  setCallingIntensity,
  onSetLowPower,
  onChangeZoneIntensity,
  lowPowerOptions,
  zone,
  isMirrorToZoneD,
  isMirrorToZoneB,
  isOntario,
}) => {
  const handleClick = () => {};
  return (
    <>
      <div
        className={
          !enableLowPower || (isMirrorToZoneD && zone === ZONE.ZONE_D) || (isMirrorToZoneB && zone === ZONE.ZONE_B)
            ? "d-flex align-items-center cursor-na"
            : "d-flex align-items-center"
        }
      >
        <div className="d-flex pt-10">
          {setCallingLowPower && enableLowPower ? (
            <img
              src={checked}
              className={
                !enableLowPower ||
                (isMirrorToZoneD && zone === ZONE.ZONE_D) ||
                (isMirrorToZoneB && zone === ZONE.ZONE_B)
                  ? "img-fluid mr-2 check-height cursor-na disable-image mt-1"
                  : "img-fluid cursor-pt mr-2 check-height mt-1"
              }
              alt=""
              onClick={() => {
                onSetLowPower(false, zone, "Calling");
              }}
              onKeyDown={handleClick}
              data-tip={CALLING_LOWPOWER_TOOLTIP}
              data-for="callinglowpower"
            />
          ) : (
            <img
              src={unchecked}
              className={
                !enableLowPower ||
                (isMirrorToZoneD && zone === ZONE.ZONE_D) ||
                (isMirrorToZoneB && zone === ZONE.ZONE_B)
                  ? "img-fluid mr-2 check-height cursor-na disable-image mt-1"
                  : "img-fluid cursor-pt mr-2 check-height mt-1"
              }
              alt=""
              onClick={() => {
                onSetLowPower(true, zone, "Calling");
              }}
              onKeyDown={handleClick}
              data-tip={CALLING_LOWPOWER_TOOLTIP}
              data-for="callinglowpower"
            />
          )}
          <div className="d-flex mr-4 flex-wrap">
            <label className="mr-3 w-90" htmlFor="callinglowpower">
              Set Calling Low Power
            </label>
            {systemType?.value !== "STANDALONE_LIGHTS" ? (
              <div
                className={`${
                  !setCallingLowPower ||
                  (isMirrorToZoneD && zone === ZONE.ZONE_D) ||
                  (isMirrorToZoneB && zone === ZONE.ZONE_B)
                    ? "cursor-na"
                    : "cursor-pt"
                }`}
              >
                <Select
                  value={setCallingIntensity}
                  onChange={(e) => {
                    onChangeZoneIntensity(e, zone, "Calling");
                  }}
                  options={lowPowerOptions}
                  isDisabled={
                    (isMirrorToZoneD && zone === ZONE.ZONE_D) ||
                    (isMirrorToZoneB && zone === ZONE.ZONE_B) ||
                    !setCallingLowPower
                  }
                  className="select-sdd select-sdd-sm mg-top-15"
                  autoFocus={false}
                  styles={helper.getDropDownStyleZoneRtnAbs(
                    (isMirrorToZoneD && zone === ZONE.ZONE_D) ||
                      (isMirrorToZoneB && zone === ZONE.ZONE_B) ||
                      !setCallingLowPower
                  )}
                  classNamePrefix="react-select"
                  menuPortalTarget={document.body}
                  menuPosition="fixed"
                />
              </div>
            ) : null}
          </div>
        </div>
        <ReactTooltip place="right" multiline id="callinglowpower" type="light" effect="solid" className="text-left" />
        {!isOntario ? (
          <div className="d-flex pt-10">
            {setBlockingLowPower && enableLowPower ? (
              <img
                src={checked}
                className={
                  !enableLowPower ||
                  (isMirrorToZoneD && zone === ZONE.ZONE_D) ||
                  (isMirrorToZoneB && zone === ZONE.ZONE_B)
                    ? "img-fluid mr-2 check-height cursor-na disable-image mt-1"
                    : "img-fluid cursor-pt mr-2 check-height mt-1"
                }
                alt=""
                onClick={() => {
                  onSetLowPower(false, zone, "Blocking");
                }}
                onKeyDown={handleClick}
                data-tip={BLOCKING_LOWPOWER_TOOLTIP}
                data-for="lowpower"
              />
            ) : (
              <img
                src={unchecked}
                className={
                  !enableLowPower ||
                  (isMirrorToZoneD && zone === ZONE.ZONE_D) ||
                  (isMirrorToZoneB && zone === ZONE.ZONE_B)
                    ? "img-fluid mr-2 check-height cursor-na disable-image mt-1"
                    : "img-fluid cursor-pt mr-2 check-height mt-1"
                }
                alt=""
                onClick={() => {
                  onSetLowPower(true, zone, "Blocking");
                }}
                onKeyDown={handleClick}
                data-tip={BLOCKING_LOWPOWER_TOOLTIP}
                data-for="lowpower"
              />
            )}
            <div className="d-flex mr-2 flex-wrap">
              <label className="mr-3 w-90" htmlFor="lowPower">
                Set Blocking Low Power
              </label>
              {systemType?.value !== "STANDALONE_LIGHTS" ? (
                <div
                  className={`${
                    !setBlockingLowPower ||
                    (isMirrorToZoneD && zone === ZONE.ZONE_D) ||
                    (isMirrorToZoneB && zone === ZONE.ZONE_B)
                      ? "cursor-na"
                      : "cursor-pt"
                  }`}
                >
                  <Select
                    value={setBlockingIntensity}
                    onChange={(e) => {
                      onChangeZoneIntensity(e, zone, "Blocking");
                    }}
                    options={lowPowerOptions}
                    isDisabled={
                      (isMirrorToZoneD && zone === ZONE.ZONE_D) ||
                      (isMirrorToZoneB && zone === ZONE.ZONE_B) ||
                      !setBlockingLowPower
                    }
                    className="select-sdd select-sdd-sm mg-top-15"
                    autoFocus={false}
                    styles={helper.getDropDownStyleZoneRtnAbs(
                      (isMirrorToZoneD && zone === ZONE.ZONE_D) ||
                        (isMirrorToZoneB && zone === ZONE.ZONE_B) ||
                        !setBlockingLowPower
                    )}
                    menuPortalTarget={document.body}
                    menuPosition="fixed"
                    classNamePrefix="react-select"
                  />
                </div>
              ) : null}
            </div>
          </div>
        ) : null}
        <ReactTooltip place="right" multiline id="lowpower" type="light" effect="solid" className="text-left" />
      </div>
    </>
  );
};

export default LowPowerComponent;

// zone component
import React from "react";
import axios from "axios";
import Modal from "react-bootstrap/Modal";
import ReactTooltip from "react-tooltip";
import { DragDropContext } from "react-beautiful-dnd";
import { Subscription } from "rxjs";
import { withRouter } from "react-router-dom";

import WhelenRImg from "../../../assets/images/W-Red.svg";

import ZoneServices from "../../../services/ZoneService";
import ZoneSidebarPage from "./ZoneSidebarPage";
import ZoneContent from "./ZoneContent";
import ZoneSaveAsPage from "./ZoneSaveAsPage";
import ZoneShareConfiguration from "./ZoneShareConfiguration";
import ConfigService from "../../../services/ConfigService";
import AuthService from "../../../services/AuthService";
import Helpers from "../../../helper/helper";

import config from "../../../config/config";
import ConfigDetails from "./ConfigDetails";
import ConfigListModal from "./ConfigListModal";
import CalculationWrngModal from "./CalculationWrngModal";
import ChangesWarningModal from "../../Common/ChangesWarningModal";
import Logout from "../../../helper/logout";
import PreCalculationWrngModal from "./PreCalculationWrngModal";
import PreCalIndividualLightSelectionModal from "./PreCalIndividualLightSelectionModal";
import {
  ONTARIO,
  LOW_PERCENTAGE,
  ONTARIO_STANDARD,
  ZONE,
  DEFAULT_LOW_POWER,
  BLOCKING_MODE,
} from "../../../services/Constant";
import DeleteConfiguration from "./DeleteConfiguration";

const { SUCCESS_MESSAGE, ERROR_MESSAGE } = config;
const systemTypes = [
  {
    label: "Standalone Lights",
    value: "STANDALONE_LIGHTS",
  },
  {
    label: "Lighting Control System",
    value: "LIGHTING_CONTROL_SYSTEM",
  },
];
const SIZES = {
  GREATER_THAN: "GREATER_THAN_25",
  LESS_THAN: "LESS_THAN_25",
};
const standAloneOptions = [
  {
    label: "High",
    value: "High",
  },
  {
    label: "Low",
    value: "Low",
  },
  {
    label: "Steady",
    value: "Steady",
  },
  {
    label: "0%",
    value: "0%",
  },
];
const { INFO_MESSAGE } = config;

// component for the handle zone page data
class ZonePage extends React.Component {
  constructor(props) {
    super(props);
    // get data from the home page and set to the state
    const configNameData = ConfigService.getConfigNameFromStorage();
    const vehicleSize = ConfigService.getVehicleSizeFromStorage();
    const configDetails = ConfigService.getConfigDetailsFromStorage();
    // get lights data from the local storage
    const lightsConfigs = ZoneServices.getLightsDataFromLocalStorage();
    this.subscription = new Subscription();
    this.state = {
      zoneLightsData: {
        zoneAData: [],
        zoneBData: [],
        zoneCData: [],
        zoneDData: [],
        zoneADataUpper: [],
        zoneBDataUpper: [],
        zoneCDataUpper: [],
        zoneDDataUpper: [],
        zoneADataLower: [],
        zoneBDataLower: [],
        zoneCDataLower: [],
        zoneDDataLower: [],
      },
      tempSourcedataKey: "",
      mySources: [],
      commonlyUsed: [],
      configName: configDetails ? configDetails.configName : configNameData,
      systemType: configDetails
        ? configDetails.systemType === "STANDALONE_LIGHTS"
          ? systemTypes[0]
          : systemTypes[1]
        : systemTypes[0],
      jobName: configDetails ? configDetails.job : "",
      departmentName: configDetails ? configDetails.department : "",
      vehicleName: configDetails ? configDetails.vehicle : "",
      vinName: configDetails ? configDetails.vin : "",
      contractName: configDetails ? configDetails.contract : "",
      ownerEmail: configDetails && configDetails.ownerEmail ? configDetails.ownerEmail : "",
      selectedConfig: configDetails,
      isGreaterThan: configDetails ? configDetails.vehicleSize !== SIZES.LESS_THAN : vehicleSize !== SIZES.LESS_THAN,
      lightsData: lightsConfigs,
      vehicleSize,
      standardName: configDetails ? configDetails.standardName : ConfigService.getStdNameFromStorage(),
      isMirrorToZoneD: configDetails ? configDetails.isMirroredTo === "D" : false,
      isMirrorToZoneB: configDetails ? configDetails.isMirroredTo === "B" : false,
      note: configDetails ? configDetails.note : "",
      loading: false,
      isResultAvailable: false,
      performCalculationData: null,
      showConfigDetails: false,
      isSaveAsModal: false,
      isShareConfigModal: false,
      isConfigListModal: false,
      showCalculationWarning: false,
      isDirty: false,
      changeRequest: "",
      openConfigReq: "",
      logoutReq: false,
      preCalModalShow: false,
      preCalIndividualLightSelModalShow: false,
      excludedLightsInCalculation: [],
      excludedLightsDuoTrioInCalculation: [],
      printWarning: false,
      isDeleteModal: false,
      lowPowerOptions: this.calculateOptions(),
      setBlockingLowPowerZoneA:
        configDetails && configDetails?.setBlockingLowPowerZoneA ? configDetails?.setBlockingLowPowerZoneA : false,
      setBlockingIntensityZoneA:
        configDetails && configDetails?.setBlockingIntensityZoneA
          ? configDetails?.setBlockingIntensityZoneA
          : DEFAULT_LOW_POWER,
      enableLowPowerZoneA: false,
      setBlockingLowPowerZoneB:
        configDetails && configDetails?.setBlockingLowPowerZoneB ? configDetails?.setBlockingLowPowerZoneB : false,
      setBlockingIntensityZoneB:
        configDetails && configDetails?.setBlockingIntensityZoneB
          ? configDetails?.setBlockingIntensityZoneB
          : DEFAULT_LOW_POWER,
      enableLowPowerZoneB: false,
      setBlockingLowPowerZoneC:
        configDetails && configDetails?.setBlockingLowPowerZoneC ? configDetails?.setBlockingLowPowerZoneC : false,
      setBlockingIntensityZoneC:
        configDetails && configDetails?.setBlockingIntensityZoneC
          ? configDetails?.setBlockingIntensityZoneC
          : DEFAULT_LOW_POWER,
      enableLowPowerZoneC: false,
      setBlockingLowPowerZoneD:
        configDetails && configDetails?.setBlockingLowPowerZoneD ? configDetails?.setBlockingLowPowerZoneD : false,
      setBlockingIntensityZoneD:
        configDetails && configDetails?.setBlockingIntensityZoneD
          ? configDetails?.setBlockingIntensityZoneD
          : DEFAULT_LOW_POWER,
      enableLowPowerZoneD: false,
      setCallingLowPowerZoneA:
        configDetails && configDetails?.setCallingLowPowerZoneA ? configDetails?.setCallingLowPowerZoneA : false,
      setCallingIntensityZoneA:
        configDetails && configDetails?.setCallingIntensityZoneA
          ? configDetails?.setCallingIntensityZoneA
          : DEFAULT_LOW_POWER,
      setCallingLowPowerZoneB:
        configDetails && configDetails?.setCallingLowPowerZoneB ? configDetails?.setCallingLowPowerZoneB : false,
      setCallingIntensityZoneB:
        configDetails && configDetails?.setCallingIntensityZoneB
          ? configDetails?.setCallingIntensityZoneB
          : DEFAULT_LOW_POWER,
      setCallingLowPowerZoneC:
        configDetails && configDetails?.setCallingLowPowerZoneC ? configDetails?.setCallingLowPowerZoneC : false,
      setCallingIntensityZoneC:
        configDetails && configDetails?.setCallingIntensityZoneC
          ? configDetails?.setCallingIntensityZoneC
          : DEFAULT_LOW_POWER,
      setCallingLowPowerZoneD:
        configDetails && configDetails?.setCallingLowPowerZoneD ? configDetails?.setCallingLowPowerZoneD : false,
      setCallingIntensityZoneD:
        configDetails && configDetails?.setCallingIntensityZoneD
          ? configDetails?.setCallingIntensityZoneD
          : DEFAULT_LOW_POWER,
    };
  }

  // render on start of the component
  componentDidMount() {
    const { standardName } = this.state;
    this.axiosCancelSource = axios.CancelToken.source();
    if (!standardName) {
      const date = new Date();
      const day = date.toDateString().slice(date.toDateString().indexOf(" ") + 1, date.toDateString().length);
      const currentTime = date.toLocaleTimeString().split(":").join(".");
      const currentDay = day.split(" ").join("-");
      const currentDate = `${currentDay}-${currentTime}`;
      const stdOpt = ConfigService.getStandardListFromLocalStorage()[0];
      const request = {
        configName: `${stdOpt.label}-${currentDate}`,
        vehicleSize: "GREATER_THAN_25",
        stdName: stdOpt.value,
      };
      ConfigService.setConfigToSessionStorage(request);
      this.setState({
        configName: request.configName,
        vehicleSize: request.vehicleSize,
        standardName: request.stdName,
      });
    }
    this.subscription.add(
      ConfigService.getSaveMenu().subscribe((data) => {
        if (data) {
          const { selectedConfig, loading } = this.state;
          this.closePerformCalculationResult();
          if (selectedConfig && !loading) {
            this.setState({ loading: true }, () => this.updateConfiguration(selectedConfig, false));
          }
          if (!selectedConfig && !loading) {
            this.setState({ loading: true }, () => this.saveConfiguration(false));
          }
        }
      })
    );
    const { lightsData, showConfigDetails } = this.state;
    this.subscription.add(
      ZoneServices.getLocalData().subscribe((data) => {
        if (data && !lightsData?.length) {
          this.setState({ lightsData: ZoneServices.getLightsDataFromLocalStorage() });
        }
      })
    );
    this.subscription.add(
      ConfigService.getConfigDetailMenuOption().subscribe((data) => {
        if (data && !showConfigDetails) {
          this.closePerformCalculationResult();
          this.setState({ showConfigDetails: true });
        }
      })
    );

    this.subscription.add(
      ConfigService.getSaveAsMenu().subscribe((data) => {
        if (data) {
          this.setState({ isSaveAsModal: true, performCalculationData: null, isResultAvailable: false });
        }
      })
    );

    this.subscription.add(
      ConfigService.getDelConfig().subscribe((data) => {
        if (data) {
          this.setState({ isDeleteModal: true });
        }
      })
    );

    this.subscription.add(
      ConfigService.getOpenMenu().subscribe((data) => {
        if (data) {
          this.setState({ isConfigListModal: true, performCalculationData: null, isResultAvailable: false });
        }
      })
    );
    this.subscription.add(
      ConfigService.getPerformPrePdfOperations().subscribe((data) => {
        if (data) {
          this.checkForInactiveLights();
        }
      })
    );
    this.subscription.add(
      ConfigService.getNewConfOperations().subscribe((data) => {
        const { isDirty } = this.state;
        if (data) {
          if (isDirty) {
            this.setState({ changeRequest: "new" });
          } else {
            this.navigateToHomepage();
          }
        }
      })
    );

    this.subscription.add(
      ConfigService.getLogoutOperations().subscribe((data) => {
        const { isDirty } = this.state;
        if (data) {
          if (isDirty) {
            this.setState({ changeRequest: "logout" });
          } else {
            this.setState({ logoutReq: true });
          }
        }
      })
    );
    // Subscription to open share configuration menu
    this.subscription.add(
      ConfigService.getShareConfig().subscribe((data) => {
        if (data) {
          this.setState({ isShareConfigModal: true });
        }
      })
    );

    const { selectedConfig } = this.state;
    if (selectedConfig) {
      this.getConfigLights();
      this.getConfigLightsByUser(selectedConfig.id, true); // if existing configuration check both my source and saved lights
    } else {
      this.getConfigLightsByUser(false, true); // if new configuration check for saved lights
    }
  }

  componentWillUnmount() {
    this.subscription.unsubscribe();
    this.axiosCancelSource.cancel("request canceled");
  }

  navigateToHomepage = () => {
    const { history } = this.props;
    history.push({
      pathname: "/home",
    });
  };

  getZoneLightCount = () => {
    const { zoneLightsData } = this.state;
    const max = 0;
    // eslint-disable-next-line no-restricted-syntax
    for (const [, value] of Object.entries(zoneLightsData)) {
      if (value.length) {
        return 1;
      }
    }
    return max;
  };

  closeConfigDetailPopup = (info) => {
    const { selectedConfig } = this.state;
    if (!info) {
      this.setState({ showConfigDetails: false });
    } else {
      this.setState({ loading: true }, () => this.updateConfigurationDetails(info, selectedConfig));
    }
  };

  openConfigurationFromList = (req) => {
    this.setState(
      {
        selectedConfig: req,
        userId: req.userId,
        emailId: req.emailId,
        configName: req.configName,
        systemType: req.systemType === "STANDALONE_LIGHTS" ? systemTypes[0] : systemTypes[1],
        jobName: req.job,
        departmentName: req.department,
        contractName: req.contract,
        vehicleName: req.vehicle,
        vinName: req.vin,
        vehicleSize: req.vehicleSize,
        standardName: req.standardName,
        ownerEmail: req.ownerEmail ? req.ownerEmail : "",
        isMirrorToZoneD: req ? req.isMirroredTo === "D" : false,
        isMirrorToZoneB: req ? req.isMirroredTo === "B" : false,
        setBlockingLowPowerZoneA: req && req.setBlockingLowPowerZoneA ? req.setBlockingLowPowerZoneA : false,
        setBlockingLowPowerZoneB: req && req.setBlockingLowPowerZoneB ? req.setBlockingLowPowerZoneB : false,
        setBlockingIntensityZoneA:
          req && req.setBlockingIntensityZoneA ? req.setBlockingIntensityZoneA : DEFAULT_LOW_POWER,
        setBlockingIntensityZoneB:
          req && req.setBlockingIntensityZoneB ? req.setBlockingIntensityZoneB : DEFAULT_LOW_POWER,
        setBlockingLowPowerZoneC: req && req.setBlockingLowPowerZoneC ? req.setBlockingLowPowerZoneC : false,
        setBlockingLowPowerZoneD: req && req.setBlockingLowPowerZoneD ? req.setBlockingLowPowerZoneD : false,
        setBlockingIntensityZoneC:
          req && req.setBlockingIntensityZoneC ? req.setBlockingIntensityZoneC : DEFAULT_LOW_POWER,
        setBlockingIntensityZoneD:
          req && req.setBlockingIntensityZoneD ? req.setBlockingIntensityZoneD : DEFAULT_LOW_POWER,
        setCallingLowPowerZoneA: req && req.setCallingLowPowerZoneA ? req.setCallingLowPowerZoneA : false,
        setCallingLowPowerZoneB: req && req.setCallingLowPowerZoneB ? req.setCallingLowPowerZoneB : false,
        setCallingIntensityZoneA:
          req && req.setCallingIntensityZoneA ? req.setCallingIntensityZoneA : DEFAULT_LOW_POWER,
        setCallingIntensityZoneB:
          req && req.setCallingIntensityZoneB ? req.setCallingIntensityZoneB : DEFAULT_LOW_POWER,
        setCallingLowPowerZoneC: req && req.setCallingLowPowerZoneC ? req.setCallingLowPowerZoneC : false,
        setCallingLowPowerZoneD: req && req.setCallingLowPowerZoneD ? req.setCallingLowPowerZoneD : false,
        setCallingIntensityZoneC:
          req && req.setCallingIntensityZoneC ? req.setCallingIntensityZoneC : DEFAULT_LOW_POWER,
        setCallingIntensityZoneD:
          req && req.setCallingIntensityZoneD ? req.setCallingIntensityZoneD : DEFAULT_LOW_POWER,
        note: req.note,
        isGreaterThan: req.vehicleSize !== SIZES.LESS_THAN,
        isDirty: false,
      },
      () => this.getConfigLights()
    );
  };

  // used for close config list as modal
  closeConfigListModal = (req) => {
    const { isDirty } = this.state;
    this.setState({ isConfigListModal: false });
    if (req && isDirty) {
      this.setState({ openConfigReq: req, changeRequest: "open" });
    } else if (req) {
      this.openConfigurationFromList(req);
      // this.getMySources(req.id);
      this.getConfigLightsByUser(req.id, false);
    }
  };

  calculateOptions = () => {
    const optionList = [];
    let i;
    for (i = 100; i >= 5; i -= 5) {
      if (i % 5 === 0) {
        const obj = {
          value: i,
          label: `${i}%`,
        };
        optionList.push(obj);
      }
    }
    optionList.push(standAloneOptions[3]);
    optionList.push(standAloneOptions[2]);
    return optionList;
  };

  setIntensityOptions = (type) => {
    const { zoneLightsData } = this.state;
    const tempdata = JSON.parse(JSON.stringify(zoneLightsData));
    const options = type === "STANDALONE_LIGHTS" ? standAloneOptions : this.calculateOptions();
    /* eslint-disable no-restricted-syntax */
    for (let [, value] of Object.entries(tempdata)) {
      const tempZones = [];
      value.forEach((light) => {
        const templight = light;
        if (type === "STANDALONE_LIGHTS" && !light.lowPower) {
          if (Object.keys(light?.colors).length === 3) {
            templight.call_intensity = options[0]?.value;
            templight.block_intensity = options[0]?.value;
            templight.call_intensity2 = options[0]?.value;
            templight.block_intensity2 = options[0]?.value;
            templight.call_intensity3 = options[0]?.value;
            templight.block_intensity3 = options[0]?.value;
          } else if (Object.keys(light?.colors).length === 2) {
            templight.call_intensity = options[0]?.value;
            templight.block_intensity = options[0]?.value;
            templight.call_intensity2 = options[0]?.value;
            templight.block_intensity2 = options[0]?.value;
          } else {
            templight.call_intensity = options[0]?.value;
            templight.block_intensity = options[0]?.value;
          }
          templight.intensityOptions = [options[0], options[2], options[3]];
        } else {
          if (Object.keys(light?.colors).length === 3) {
            templight.call_intensity = options[0]?.value;
            templight.block_intensity = options[0]?.value;
            templight.call_intensity2 = options[0]?.value;
            templight.block_intensity2 = options[0]?.value;
            templight.call_intensity3 = options[0]?.value;
            templight.block_intensity3 = options[0]?.value;
          } else if (Object.keys(light?.colors).length === 2) {
            templight.call_intensity = options[0]?.value;
            templight.block_intensity = options[0]?.value;
            templight.call_intensity2 = options[0]?.value;
            templight.block_intensity2 = options[0]?.value;
          } else {
            templight.call_intensity = options[0]?.value;
            templight.block_intensity = options[0]?.value;
          }
          templight.intensityOptions = options;
        }
        tempZones.push(templight);
      });
      value = tempZones;
    }
    this.setState({ zoneLightsData: tempdata });
  };

  getIntensityOptions = (lowPower) => {
    const { systemType } = this.state;
    const options = systemType.value === "STANDALONE_LIGHTS" ? standAloneOptions : this.calculateOptions();
    if (systemType.value === "STANDALONE_LIGHTS" && !lowPower) {
      return [options[0], options[2], options[3]];
    }
    return options;
  };

  // set low power in all supported Light
  setLowPowerOptions = (val, zone, mode) => {
    if (mode === BLOCKING_MODE) {
      if (zone === ZONE.ZONE_A) {
        this.setState({ setBlockingLowPowerZoneA: val, isDirty: true });
      } else if (zone === ZONE.ZONE_B) {
        this.setState({ setBlockingLowPowerZoneB: val, isDirty: true });
      } else if (zone === ZONE.ZONE_C) {
        this.setState({ setBlockingLowPowerZoneC: val, isDirty: true });
      } else {
        this.setState({ setBlockingLowPowerZoneD: val, isDirty: true });
      }
    } else if (zone === ZONE.ZONE_A) {
      this.setState({ setCallingLowPowerZoneA: val, isDirty: true });
    } else if (zone === ZONE.ZONE_B) {
      this.setState({ setCallingLowPowerZoneB: val, isDirty: true });
    } else if (zone === ZONE.ZONE_C) {
      this.setState({ setCallingLowPowerZoneC: val, isDirty: true });
    } else {
      this.setState({ setCallingLowPowerZoneD: val, isDirty: true });
    }
    const { zoneLightsData, systemType, isMirrorToZoneD, isMirrorToZoneB } = this.state;
    const tempdata = JSON.parse(JSON.stringify(zoneLightsData));
    const options = systemType.value === "STANDALONE_LIGHTS" ? standAloneOptions : this.calculateOptions();
    /* eslint-disable no-restricted-syntax */
    for (const [, value] of Object.entries(tempdata)) {
      const tempZones = [];
      if (val) {
        value.forEach((light) => {
          const templight = light;
          if (light?.lowPower && light?.zone === zone) {
            if (mode === BLOCKING_MODE) {
              if (systemType.value === "STANDALONE_LIGHTS") {
                if (Object.keys(light?.colors).length === 3) {
                  templight.block_intensity = options[1]?.value;
                  templight.block_intensity2 = options[1]?.value;
                  templight.block_intensity3 = options[1]?.value;
                } else if (Object.keys(light?.colors).length === 2) {
                  templight.block_intensity = options[1]?.value;
                  templight.block_intensity2 = options[1]?.value;
                } else {
                  templight.block_intensity = options[1]?.value;
                }
                templight.intensityOptions = options;
              } else {
                const findIndex = options?.findIndex((item) => item.value === LOW_PERCENTAGE);
                if (findIndex !== -1) {
                  if (Object.keys(light?.colors).length === 3) {
                    templight.block_intensity = options[findIndex]?.value;
                    templight.block_intensity2 = options[findIndex]?.value;
                    templight.block_intensity3 = options[findIndex]?.value;
                  } else if (Object.keys(light?.colors).length === 2) {
                    templight.block_intensity = options[findIndex]?.value;
                    templight.block_intensity2 = options[findIndex]?.value;
                  } else {
                    templight.block_intensity = options[findIndex]?.value;
                  }
                }
                templight.intensityOptions = options;
              }
            } else {
              /* eslint-disable no-lonely-if */
              if (systemType.value === "STANDALONE_LIGHTS") {
                if (Object.keys(light?.colors).length === 3) {
                  templight.call_intensity = options[1]?.value;
                  templight.call_intensity2 = options[1]?.value;
                  templight.call_intensity3 = options[1]?.value;
                } else if (Object.keys(light?.colors).length === 2) {
                  templight.call_intensity = options[1]?.value;
                  templight.call_intensity2 = options[1]?.value;
                } else {
                  templight.call_intensity = options[1]?.value;
                }
                templight.intensityOptions = options;
              } else {
                const findIndex = options?.findIndex((item) => item.value === LOW_PERCENTAGE);
                if (findIndex !== -1) {
                  if (Object.keys(light?.colors).length === 3) {
                    templight.call_intensity = options[findIndex]?.value;
                    templight.call_intensity2 = options[findIndex]?.value;
                    templight.call_intensity3 = options[findIndex]?.value;
                  } else if (Object.keys(light?.colors).length === 2) {
                    templight.call_intensity = options[findIndex]?.value;
                    templight.call_intensity2 = options[findIndex]?.value;
                  } else {
                    templight.call_intensity = options[findIndex]?.value;
                  }
                }
                templight.intensityOptions = options;
              }
            }
            tempZones.push(templight);
          }
        });
      } else {
        if (systemType.value !== "STANDALONE_LIGHTS") {
          if (mode === BLOCKING_MODE) {
            if (zone === ZONE.ZONE_A) {
              this.setState({ setBlockingIntensityZoneA: DEFAULT_LOW_POWER, isDirty: true });
            } else if (zone === ZONE.ZONE_B) {
              this.setState({ setBlockingIntensityZoneB: DEFAULT_LOW_POWER, isDirty: true });
            } else if (zone === ZONE.ZONE_C) {
              this.setState({ setBlockingIntensityZoneC: DEFAULT_LOW_POWER, isDirty: true });
            } else {
              this.setState({ setBlockingIntensityZoneD: DEFAULT_LOW_POWER, isDirty: true });
            }
          } else if (zone === ZONE.ZONE_A) {
            this.setState({ setCallingIntensityZoneA: DEFAULT_LOW_POWER, isDirty: true });
          } else if (zone === ZONE.ZONE_B) {
            this.setState({ setCallingIntensityZoneB: DEFAULT_LOW_POWER, isDirty: true });
          } else if (zone === ZONE.ZONE_C) {
            this.setState({ setCallingIntensityZoneC: DEFAULT_LOW_POWER, isDirty: true });
          } else {
            this.setState({ setCallingIntensityZoneD: DEFAULT_LOW_POWER, isDirty: true });
          }
        }
      }
    }
    this.setState({
      zoneLightsData: tempdata,
    });
    if (zone === ZONE.ZONE_B && isMirrorToZoneD) {
      setTimeout(() => {
        this.setLowPowerOptions(val, ZONE.ZONE_D, mode);
      }, 1);
    }
    if (zone === ZONE.ZONE_D && isMirrorToZoneB) {
      setTimeout(() => {
        this.setLowPowerOptions(val, ZONE.ZONE_B, mode);
      }, 1);
    }
  };

  // update calling/blocking low power checkbox stat
  updateLowPower = () => {
    const { zoneLightsData, isMirrorToZoneD, isMirrorToZoneB } = this.state;
    const tempdata = JSON.parse(JSON.stringify(zoneLightsData));
    /* eslint-disable no-restricted-syntax */
    const lowPowerData = [];
    for (const [, value] of Object.entries(tempdata)) {
      value?.forEach((light) => {
        if (light?.lowPower) {
          lowPowerData.push(light?.zone);
        }
      });
    }
    if (lowPowerData?.length > 0) {
      if (lowPowerData.includes(ZONE.ZONE_A)) {
        this.setState({ enableLowPowerZoneA: true });
      } else {
        this.setState({
          enableLowPowerZoneA: false,
          setBlockingLowPowerZoneA: false,
          setBlockingIntensityZoneA: DEFAULT_LOW_POWER,
          setCallingLowPowerZoneA: false,
          setCallingIntensityZoneA: DEFAULT_LOW_POWER,
        });
      }
      if (lowPowerData.includes(ZONE.ZONE_B)) {
        this.setState({ enableLowPowerZoneB: true });
      } else {
        this.setState({
          enableLowPowerZoneB: false,
          setBlockingLowPowerZoneB: false,
          setBlockingIntensityZoneB: DEFAULT_LOW_POWER,
          setCallingLowPowerZoneB: false,
          setCallingIntensityZoneB: DEFAULT_LOW_POWER,
        });
        if (isMirrorToZoneD) {
          this.setState({
            setBlockingLowPowerZoneD: false,
            setBlockingIntensityZoneD: DEFAULT_LOW_POWER,
            setCallingLowPowerZoneD: false,
            setCallingIntensityZoneD: DEFAULT_LOW_POWER,
          });
        }
      }
      if (lowPowerData.includes(ZONE.ZONE_C)) {
        this.setState({ enableLowPowerZoneC: true });
      } else {
        this.setState({
          enableLowPowerZoneC: false,
          setBlockingLowPowerZoneC: false,
          setBlockingIntensityZoneC: DEFAULT_LOW_POWER,
          setCallingLowPowerZoneC: false,
          setCallingIntensityZoneC: DEFAULT_LOW_POWER,
        });
      }
      if (lowPowerData.includes(ZONE.ZONE_D)) {
        this.setState({ enableLowPowerZoneD: true });
      } else {
        this.setState({
          enableLowPowerZoneD: false,
          setBlockingLowPowerZoneD: false,
          setBlockingIntensityZoneD: DEFAULT_LOW_POWER,
          setCallingLowPowerZoneD: false,
          setCallingIntensityZoneD: DEFAULT_LOW_POWER,
        });
        if (isMirrorToZoneB) {
          this.setState({
            setBlockingLowPowerZoneB: false,
            setBlockingIntensityZoneB: DEFAULT_LOW_POWER,
            setCallingLowPowerZoneB: false,
            setCallingIntensityZoneB: DEFAULT_LOW_POWER,
          });
        }
      }
    } else {
      this.setState({
        enableLowPowerZoneA: false,
        enableLowPowerZoneB: false,
        enableLowPowerZoneC: false,
        enableLowPowerZoneD: false,
        setBlockingLowPowerZoneA: false,
        setBlockingLowPowerZoneB: false,
        setBlockingLowPowerZoneC: false,
        setBlockingLowPowerZoneD: false,
        setCallingLowPowerZoneA: false,
        setCallingLowPowerZoneB: false,
        setCallingLowPowerZoneC: false,
        setCallingLowPowerZoneD: false,
      });
    }
  };

  // ontario calling low power checkbox stat
  updateCallingLowPower = () => {
    const { zoneLightsData, isMirrorToZoneD, isMirrorToZoneB } = this.state;
    const tempdata = JSON.parse(JSON.stringify(zoneLightsData));
    /* eslint-disable no-restricted-syntax */
    const lowPowerData = [];
    for (const [, value] of Object.entries(tempdata)) {
      value?.forEach((light) => {
        if (light?.lowPower) {
          lowPowerData.push(light?.zone);
        }
      });
    }
    if (lowPowerData?.length > 0) {
      if (lowPowerData.includes(ZONE.ZONE_A)) {
        this.setState({ enableLowPowerZoneA: true });
      } else {
        this.setState({
          enableLowPowerZoneA: false,
          setCallingLowPowerZoneA: false,
          setCallingIntensityZoneA: DEFAULT_LOW_POWER,
        });
      }
      if (lowPowerData.includes(ZONE.ZONE_B)) {
        this.setState({ enableLowPowerZoneB: true });
      } else {
        this.setState({
          enableLowPowerZoneB: false,
          setCallingLowPowerZoneB: false,
          setCallingIntensityZoneB: DEFAULT_LOW_POWER,
        });
        if (isMirrorToZoneD) {
          this.setState({
            setCallingLowPowerZoneD: false,
            setCallingIntensityZoneD: DEFAULT_LOW_POWER,
          });
        }
      }
      if (lowPowerData.includes(ZONE.ZONE_C)) {
        this.setState({ enableLowPowerZoneC: true });
      } else {
        this.setState({
          enableLowPowerZoneC: false,
          setCallingLowPowerZoneC: false,
          setCallingIntensityZoneC: DEFAULT_LOW_POWER,
        });
      }
      if (lowPowerData.includes(ZONE.ZONE_D)) {
        this.setState({ enableLowPowerZoneD: true });
      } else {
        this.setState({
          enableLowPowerZoneD: false,
          setCallingLowPowerZoneD: false,
          setCallingIntensityZoneD: DEFAULT_LOW_POWER,
        });
        if (isMirrorToZoneB) {
          this.setState({
            setCallingLowPowerZoneB: false,
            setCallingIntensityZoneB: DEFAULT_LOW_POWER,
          });
        }
      }
    } else {
      this.setState({
        enableLowPowerZoneA: false,
        enableLowPowerZoneB: false,
        enableLowPowerZoneC: false,
        enableLowPowerZoneD: false,
        setCallingLowPowerZoneA: false,
        setCallingLowPowerZoneB: false,
        setCallingLowPowerZoneC: false,
        setCallingLowPowerZoneD: false,
      });
    }
  };

  // get obstruct data while adding light in to zone
  getObstructData = (zone, lightType) => {
    const obstruct = [];
    if (
      (zone === "A" || zone === "C") &&
      (lightType === "Beacon" ||
        lightType === "ImportedLightbar" ||
        lightType === "Lightbar" ||
        lightType === "Specialty")
    ) {
      if (zone === "A") {
        obstruct.push("C");
      } else {
        obstruct.push("A");
      }
    }
    return obstruct;
  };

  getConfigLights = async () => {
    this.setState({ loading: true });
    const zoneLightData = {
      zoneAData: [],
      zoneBData: [],
      zoneCData: [],
      zoneDData: [],
      zoneADataUpper: [],
      zoneBDataUpper: [],
      zoneCDataUpper: [],
      zoneDDataUpper: [],
      zoneADataLower: [],
      zoneBDataLower: [],
      zoneCDataLower: [],
      zoneDDataLower: [],
    };
    const { selectedConfig, lightsData, standardName } = this.state;
    const [configsLights] = await Promise.all([
      ConfigService.getConfigLights({ config_id: selectedConfig?.id }).catch(() => {
        this.setState({ loading: false });
      }),
    ]);
    if (configsLights && configsLights.data) {
      const { data } = configsLights;
      data.map((lights) => {
        // select lights data as per zone
        const lightData = lights;
        let zoneName = `zone${lights.zone}Data`;
        let zoneArea = `zoneContent-${lights.zone}`;
        if (lights.level === "upper") {
          zoneName = `zone${lights.zone}DataUpper`;
          zoneArea = `zoneContent-${lights.zone}-upper`;
        } else if (lights.level === "lower") {
          zoneName = `zone${lights.zone}DataLower`;
          zoneArea = `zoneContent-${lights.zone}-lower`;
        }
        /* eslint-disable array-callback-return */
        // Missing/Inactive lightheads data for tooltip
        let tooltipData = lights.sDisplayName;
        const missingLightheads = [];
        const inactiveLightheads = [];
        const excludedLightheads = [];
        let tooltipMissingLighthead;
        let tooltipInactiveData;
        let showExlm = false;
        let showHash = false;
        let importedBxrFileName = "";

        if (
          lights.lightType === "Lightbar" ||
          lights.lightType === "ImportedLightbar" ||
          lights.lightType === "Specialty"
        ) {
          if (lights.isActive === false || lights.excluded === true) {
            if (lights.isActive === false && lights.excluded === true) {
              tooltipData = `Excluded and Inactive LightSource: ${lights.sDisplayName}`;
              showHash = true;
              showExlm = true;
            } else if (lights.isActive === false && lights.excluded === false) {
              tooltipData = `Inactive LightSource: ${lights.sDisplayName}`;
              showExlm = true;
            } else if (lights.isActive === true && lights.excluded === true) {
              tooltipData = `Excluded LightSource: ${lights.sDisplayName}`;
              showHash = true;
            }
          } else {
            if (lights.missingLightheads?.length > 0) {
              lights.missingLightheads.map((model) => {
                missingLightheads.push(model.LightModelNumber);
              });
            }
            if (lights.inactiveLightheads?.length > 0) {
              lights.inactiveLightheads.map((model) => {
                inactiveLightheads.push(model.LightModelNumber);
              });
            }
            if (lights.excludedLightheads?.length > 0) {
              lights.excludedLightheads.map((model) => {
                excludedLightheads.push(model.LightModelNumber);
              });
            }
            if (missingLightheads?.length > 0) {
              const missingModels = missingLightheads.join(", ");
              tooltipMissingLighthead = `<br />Missing Lighthead(s): ${missingModels}`;
              showExlm = true;
              tooltipData += tooltipMissingLighthead;
            }
            if (inactiveLightheads?.length > 0) {
              const inactiveModels = inactiveLightheads.join(", ");
              tooltipInactiveData = `<br />Inactive Lighthead(s): ${inactiveModels}`;
              showExlm = true;
              tooltipData += tooltipInactiveData;
            }
            if (excludedLightheads?.length > 0) {
              const excludedModels = excludedLightheads.join(", ");
              tooltipInactiveData = `<br />Excluded Lighthead(s): ${excludedModels}`;
              showHash = true;
              tooltipData += tooltipInactiveData;
            }
            if (lights?.lightType === "ImportedLightbar") {
              tooltipData += `<br />File Name: ${lights.importedBxrFileName}`;
              importedBxrFileName = lights.importedBxrFileName;
            }
          }
        } else if (lights.isActive === false || lights.excluded === true) {
          if (lights.isActive === false && lights.excluded === true) {
            tooltipData = `Excluded and Inactive LightSource: ${lights.sDisplayName}`;
            showHash = true;
            showExlm = true;
          } else if (lights.isActive === false && lights.excluded === false) {
            tooltipData = `Inactive LightSource: ${lights.sDisplayName}`;
            showExlm = true;
          } else if (lights.isActive === true && lights.excluded === true) {
            tooltipData = `Excluded LightSource: ${lights.sDisplayName}`;
            showHash = true;
          }
        }
        lightData.showExlm = showExlm;
        lightData.showHash = showHash;
        lightData.tooltipData = tooltipData;
        lightData.importedBxrFileName = importedBxrFileName;
        const zoneData = zoneLightData[zoneName] || [];
        const colorsArray = Object.values(lights.colors);
        const findIndex = lightsData?.findIndex((zone) => zone.id === lights.productId);
        if (findIndex >= 0 && findIndex !== "" && findIndex !== -1) {
          const object = this.fillObject(zoneArea, colorsArray, lightsData[findIndex], lights);
          zoneData.push(JSON.parse(JSON.stringify(object)));
          zoneLightData[zoneName] = JSON.parse(JSON.stringify(zoneData));
        } else {
          const object = this.fillObject(zoneArea, colorsArray, {}, lights);
          zoneData.push(JSON.parse(JSON.stringify(object)));
          zoneLightData[zoneName] = JSON.parse(JSON.stringify(zoneData));
        }
        return null;
      });
      this.setState({ zoneLightsData: zoneLightData, loading: false });
      if (!ONTARIO_STANDARD.includes(standardName)) {
        this.updateLowPower();
      } else {
        this.updateCallingLowPower();
      }
    } else {
      this.setState({ zoneLightsData: zoneLightData, loading: false });
      if (!ONTARIO_STANDARD.includes(standardName)) {
        this.updateLowPower();
      } else {
        this.updateCallingLowPower();
      }
    }
  };

  fillObject = (zoneArea, colorsArray, dstObj, lights) => {
    const object = dstObj;
    object.lightId = lights.id;
    object.id = lights.productId;
    object.sDisplayName = lights.sDisplayName;
    object.colors = lights.colors;
    object.displayId = zoneArea;
    object.configId = lights.configId;
    object.description = lights.description;
    if (lights.modelNumber) {
      object.modelNumber = lights.modelNumber;
    }
    object.quantity = lights.qty;
    object.qty = lights.qty;
    object.lowPower = lights.lowPower;
    object.intensityOptions = this.getIntensityOptions(lights?.lowPower);
    object.block_intensity = colorsArray[0] ? colorsArray[0].block_intensity : "";
    object.call_intensity = colorsArray[0] ? colorsArray[0].call_intensity : "";
    object.block_intensity2 = colorsArray[1] ? colorsArray[1].block_intensity : "";
    object.call_intensity2 = colorsArray[1] ? colorsArray[1].call_intensity : "";
    object.block_intensity3 = colorsArray[2] ? colorsArray[2].block_intensity : "";
    object.call_intensity3 = colorsArray[2] ? colorsArray[2].call_intensity : "";
    object.rotation = Object.prototype.hasOwnProperty.call(lights, "rotation")
      ? lights.rotation === ""
        ? "0"
        : lights.rotation
      : "0";
    object.obstruct = lights.obstruct;
    object.level = lights.level;
    object.zone = lights.zone;
    object.lightType = lights.lightType;
    object.currentAverage = lights.currentAverage;
    object.currentMax = lights.currentMax;
    object.tooltipData = lights.tooltipData;
    object.showExlm = lights.showExlm;
    object.showHash = lights.showHash;
    object.importedBxrFileName = lights.importedBxrFileName;
    return object;
  };

  getConfigLightsByUser = (id, commonlyUsedLightRefresh) => {
    ConfigService.getCommonPreferenceLights({}, this.axiosCancelSource.token).then(async (response) => {
      if (response?.data) {
        const result = response.data;
        const lastkeydata = response.data.lastEvaluatedKey;
        let storeData = [];
        if ("lastEvaluatedKey" in response.data) {
          storeData = await ConfigService.getCommonPreferenceLights(lastkeydata, this.axiosCancelSource.token);
          result.data = result?.data?.concat(storeData?.data?.data);
        }
        if (result?.data) {
          result.data = this.lightDataMapping(result.data);
          if (id) {
            const mySources = result.data.filter((item) => item.configId === id);
            this.setState({ mySources });
          }
          if (commonlyUsedLightRefresh) {
            const commonlyUsed = await result.data.filter((item) => item.isSavedLightSource);
            this.setState({ commonlyUsed });
          }
        }
        if (storeData?.data && "lastEvaluatedKey" in storeData?.data) {
          await this.getLastkeydata(storeData.data.lastEvaluatedKey, id, commonlyUsedLightRefresh);
        }
      }
    });
  };

  lightDataMapping = (result) => {
    result.map((data) => {
      const dataList = data;
      let tooltipData = data?.sDisplayName;
      const missingLightheads = [];
      const inactiveLightheads = [];
      const excludedLightheads = [];
      let tooltipMissingLighthead;
      let tooltipInactiveData;
      let showExlm = false;
      let showHash = false;
      if (data?.lightType === "Lightbar" || data?.lightType === "ImportedLightbar" || data.lightType === "Specialty") {
        if (data.isActive === false || data.excluded === true) {
          if (data.isActive === false && data.excluded === true) {
            tooltipData = `Excluded and Inactive LightSource: ${data.sDisplayName}`;
            showHash = true;
            showExlm = true;
          } else if (data.isActive === false && data.excluded === false) {
            tooltipData = `Inactive LightSource: ${data.sDisplayName}`;
            showExlm = true;
          } else if (data.isActive === true && data.excluded === true) {
            tooltipData = `Excluded LightSource: ${data.sDisplayName}`;
            showHash = true;
          }
        } else {
          if (data.missingLightheads.length > 0) {
            data.missingLightheads.map((model) => {
              missingLightheads.push(model.LightModelNumber);
            });
          }
          if (data.inactiveLightheads.length > 0) {
            data.inactiveLightheads.map((model) => {
              inactiveLightheads.push(model.LightModelNumber);
            });
          }
          if (data.excludedLightheads?.length > 0) {
            data.excludedLightheads.map((model) => {
              excludedLightheads.push(model.LightModelNumber);
            });
          }
          if (missingLightheads.length > 0) {
            const missingModels = missingLightheads.join(", ");
            tooltipMissingLighthead = `<br />Missing Lighthead(s): ${missingModels}`;
            showExlm = true;
            tooltipData += tooltipMissingLighthead;
          }
          if (inactiveLightheads.length > 0) {
            const inactiveModels = inactiveLightheads.join(", ");
            tooltipInactiveData = `<br />Inactive Lighthead(s): ${inactiveModels}`;
            showExlm = true;
            tooltipData += tooltipInactiveData;
          }
          if (excludedLightheads.length > 0) {
            const excludedModels = excludedLightheads.join(", ");
            tooltipInactiveData = `<br />Excluded Lighthead(s): ${excludedModels}`;
            showHash = true;
            tooltipData += tooltipInactiveData;
          }
          if (data.lightType === "ImportedLightbar") {
            tooltipData += `<br />File Name: ${data.importedBxrFileName}`;
          }
        }
      } else if (data.isActive === false || data.excluded === true) {
        if (data.isActive === false && data.excluded === true) {
          tooltipData = `Excluded and Inactive LightSource: ${data.sDisplayName}`;
          showHash = true;
          showExlm = true;
        } else if (data.isActive === false && data.excluded === false) {
          tooltipData = `Inactive LightSource: ${data.sDisplayName}`;
          showExlm = true;
        } else if (data.isActive === true && data.excluded === true) {
          tooltipData = `Excluded LightSource: ${data.sDisplayName}`;
          showHash = true;
        }
      }
      dataList.showExlm = showExlm;
      dataList.showHash = showHash;
      dataList.tooltipData = tooltipData;
    });
    return result;
  };
  // get My sources from db

  // eslint-disable-next-line consistent-return
  getLastkeydata = async (lastkeydata, configId, commonlyUsedLightRefresh) => {
    const { commonlyUsed, selectedConfig } = this.state;
    if (lastkeydata && (!selectedConfig || configId === selectedConfig?.id)) {
      try {
        const lightData = await ConfigService.getCommonPreferenceLights(lastkeydata, this.axiosCancelSource.token);
        if (lightData?.data?.data.length) {
          lightData.data.data = this.lightDataMapping(lightData?.data?.data); // assign tooltip
          const recurrsionData = lightData.data.data.filter((item) => item.configId === configId); // filter lights to show on My Sources section
          this.setState((prevStat) => {
            const sourceArr = [...prevStat.mySources, ...recurrsionData];
            const newSourceArr = this.getUniqueSources(sourceArr); // remove duplicate lights with same source id
            return { mySources: newSourceArr };
          });
          if (commonlyUsedLightRefresh) {
            const commonly = await lightData.data.data.filter((item) => item.isSavedLightSource); // filter lights to show on My Saved Source section
            const newCommonly = [...commonly, ...commonlyUsed];
            newCommonly.sort((a, b) => a.order - b.order); // arrange in order
            const commonSources = this.getUniqueSources(newCommonly);
            this.setState({ commonlyUsed: commonSources });
          }
        }
        if ("lastEvaluatedKey" in lightData?.data && (!selectedConfig || configId === selectedConfig?.id)) {
          this.getLastkeydata(lightData?.data?.lastEvaluatedKey, configId, commonlyUsedLightRefresh);
        }
      } catch (e) {
        console.log("Request Cancel");
      }
    }
  };

  getUniqueSources = (sources) => {
    const uniqueIds = [];
    const uniqueSources = sources.filter((element) => {
      const isDuplicate = uniqueIds.includes(element.id);
      if (!isDuplicate) {
        uniqueIds.push(element.id);
        return true;
      }
      return false;
    });
    return uniqueSources;
  };

  // used for change syatem types dropdown
  changeSystemTypes = (e) => {
    this.setState({ systemType: e, isDirty: true });
    this.setIntensityOptions(e.value);
    this.setState({
      setBlockingLowPowerZoneA: false,
      setBlockingIntensityZoneA: DEFAULT_LOW_POWER,
      setBlockingLowPowerZoneB: false,
      setBlockingIntensityZoneB: DEFAULT_LOW_POWER,
      setBlockingLowPowerZoneC: false,
      setBlockingIntensityZoneC: DEFAULT_LOW_POWER,
      setBlockingLowPowerZoneD: false,
      setBlockingIntensityZoneD: DEFAULT_LOW_POWER,
      setCallingLowPowerZoneA: false,
      setCallingIntensityZoneA: DEFAULT_LOW_POWER,
      setCallingLowPowerZoneB: false,
      setCallingIntensityZoneB: DEFAULT_LOW_POWER,
      setCallingLowPowerZoneC: false,
      setCallingIntensityZoneC: DEFAULT_LOW_POWER,
      setCallingLowPowerZoneD: false,
      setCallingIntensityZoneD: DEFAULT_LOW_POWER,
    });
  };

  // used for set low power
  onSetLowPower = (val, zone, mode) => {
    this.setLowPowerOptions(val, zone, mode);
  };

  // Function use to set low power calling/blocking intensity
  onChangeZoneIntensity = (val, zone, mode) => {
    const { zoneLightsData, systemType, isMirrorToZoneD, isMirrorToZoneB } = this.state;
    const tempdata = JSON.parse(JSON.stringify(zoneLightsData));
    const options = systemType.value === "STANDALONE_LIGHTS" ? standAloneOptions : this.calculateOptions();
    /* eslint-disable no-restricted-syntax */
    for (const [, value] of Object.entries(tempdata)) {
      const tempZones = [];
      if (val) {
        value.forEach((light) => {
          const templight = light;
          if (light?.lowPower && light?.zone === zone) {
            if (mode === BLOCKING_MODE) {
              if (systemType.value !== "STANDALONE_LIGHTS") {
                if (zone === ZONE.ZONE_A) {
                  this.setState({ setBlockingIntensityZoneA: val, isDirty: true });
                } else if (zone === ZONE.ZONE_B) {
                  this.setState({ setBlockingIntensityZoneB: val, isDirty: true });
                } else if (zone === ZONE.ZONE_C) {
                  this.setState({ setBlockingIntensityZoneC: val, isDirty: true });
                } else {
                  this.setState({ setBlockingIntensityZoneD: val, isDirty: true });
                }
                const findIndex = options?.findIndex((item) => item.value === val.value);
                if (findIndex !== -1) {
                  if (Object.keys(light?.colors).length === 3) {
                    templight.block_intensity = options[findIndex]?.value;
                    templight.block_intensity2 = options[findIndex]?.value;
                    templight.block_intensity3 = options[findIndex]?.value;
                  } else if (Object.keys(light?.colors).length === 2) {
                    templight.block_intensity = options[findIndex]?.value;
                    templight.block_intensity2 = options[findIndex]?.value;
                  } else {
                    templight.block_intensity = options[findIndex]?.value;
                  }
                }
                templight.intensityOptions = options;
              }
            } else {
              /* eslint-disable no-lonely-if */
              if (systemType.value !== "STANDALONE_LIGHTS") {
                if (zone === ZONE.ZONE_A) {
                  this.setState({ setCallingIntensityZoneA: val, isDirty: true });
                } else if (zone === ZONE.ZONE_B) {
                  this.setState({ setCallingIntensityZoneB: val, isDirty: true });
                } else if (zone === ZONE.ZONE_C) {
                  this.setState({ setCallingIntensityZoneC: val, isDirty: true });
                } else {
                  this.setState({ setCallingIntensityZoneD: val, isDirty: true });
                }
                const findIndex = options?.findIndex((item) => item.value === val.value);
                if (findIndex !== -1) {
                  if (Object.keys(light?.colors).length === 3) {
                    templight.call_intensity = options[findIndex]?.value;
                    templight.call_intensity2 = options[findIndex]?.value;
                    templight.call_intensity3 = options[findIndex]?.value;
                  } else if (Object.keys(light?.colors).length === 2) {
                    templight.call_intensity = options[findIndex]?.value;
                    templight.call_intensity2 = options[findIndex]?.value;
                  } else {
                    templight.call_intensity = options[findIndex]?.value;
                  }
                }
                templight.intensityOptions = options;
              }
            }
            tempZones.push(templight);
          }
        });
      }
    }
    this.setState({ zoneLightsData: tempdata });
    if (zone === ZONE.ZONE_B && isMirrorToZoneD) {
      setTimeout(() => {
        this.onChangeZoneIntensity(val, ZONE.ZONE_D, mode);
      }, 1);
    }
    if (zone === ZONE.ZONE_D && isMirrorToZoneB) {
      setTimeout(() => {
        this.onChangeZoneIntensity(val, ZONE.ZONE_B, mode);
      }, 1);
    }
  };

  saveConfiguration = async (prePdfCal, action, useInactiveStatus = false) => {
    const {
      configName,
      systemType,
      jobName,
      departmentName,
      contractName,
      vehicleName,
      vinName,
      vehicleSize,
      standardName,
      isMirrorToZoneB,
      isMirrorToZoneD,
      note,
      setBlockingLowPowerZoneA,
      setBlockingLowPowerZoneB,
      setBlockingLowPowerZoneC,
      setBlockingLowPowerZoneD,
      setBlockingIntensityZoneA,
      setBlockingIntensityZoneB,
      setBlockingIntensityZoneC,
      setBlockingIntensityZoneD,
      setCallingLowPowerZoneA,
      setCallingLowPowerZoneB,
      setCallingLowPowerZoneC,
      setCallingLowPowerZoneD,
      setCallingIntensityZoneA,
      setCallingIntensityZoneB,
      setCallingIntensityZoneC,
      setCallingIntensityZoneD,
    } = this.state;
    let mirrorValue = "";
    if (isMirrorToZoneB) {
      mirrorValue = "B";
    } else if (isMirrorToZoneD) {
      mirrorValue = "D";
    }
    const userId = AuthService.getUserId();
    const emailId = AuthService.getUserName();
    const configRequest = {
      userId,
      emailId,
      configName,
      systemType: systemType.value,
      job: jobName,
      department: departmentName,
      contract: contractName,
      vehicle: vehicleName,
      vin: vinName,
      vehicleSize,
      standardName,
      isMirroredTo: mirrorValue,
      note,
      count: this.getZoneLightCount(),
      setBlockingLowPowerZoneA,
      setBlockingLowPowerZoneB,
      setBlockingLowPowerZoneC,
      setBlockingLowPowerZoneD,
      setBlockingIntensityZoneA,
      setBlockingIntensityZoneB,
      setBlockingIntensityZoneC,
      setBlockingIntensityZoneD,
      setCallingLowPowerZoneA,
      setCallingLowPowerZoneB,
      setCallingLowPowerZoneC,
      setCallingLowPowerZoneD,
      setCallingIntensityZoneA,
      setCallingIntensityZoneB,
      setCallingIntensityZoneC,
      setCallingIntensityZoneD,
    };
    ConfigService.saveConfigurations(configRequest) // create config
      .then((response) => {
        const selectedData = configRequest;
        selectedData.id = response.data.Id;
        const requests = { configDetails: selectedData, vehicleSize };
        ConfigService.setConfigToSessionStorage(requests);
        this.setState({ selectedConfig: selectedData, isDirty: false });
        if (!prePdfCal) {
          Helpers.displayNotification(SUCCESS_MESSAGE.CONFIG_CREATED, "success");
          this.setState({ loading: false });
          if (action) {
            this.selectNextAction(action);
          }
        } else {
          this.getPdfResultData(selectedData.id, useInactiveStatus);
        }
      })
      .catch((error) => {
        this.setState({ loading: false });
        Helpers.displayErrorMessage(error);
      });
  };

  // used for close save as modal
  closeSaveAsModal = () => {
    this.setState({ isSaveAsModal: false });
  };

  // used for save-as configurations.
  saveAsConfiguration = (configName) => {
    const { mySources } = this.state;
    this.setState({ loading: true, isDirty: false });
    const vehicleSize = ConfigService.getVehicleSizeFromStorage();
    const newLights = this.getRequestObjectForUpdateLights();
    const {
      systemType,
      jobName,
      departmentName,
      vehicleName,
      vinName,
      contractName,
      isMirrorToZoneB,
      isMirrorToZoneD,
      standardName,
      setBlockingLowPowerZoneA,
      setBlockingLowPowerZoneB,
      setBlockingLowPowerZoneC,
      setBlockingLowPowerZoneD,
      setBlockingIntensityZoneA,
      setBlockingIntensityZoneB,
      setBlockingIntensityZoneC,
      setBlockingIntensityZoneD,
      setCallingLowPowerZoneA,
      setCallingLowPowerZoneB,
      setCallingLowPowerZoneC,
      setCallingLowPowerZoneD,
      setCallingIntensityZoneA,
      setCallingIntensityZoneB,
      setCallingIntensityZoneC,
      setCallingIntensityZoneD,
    } = this.state;

    let mirrorValue = "";
    if (isMirrorToZoneB) {
      mirrorValue = "B";
    } else if (isMirrorToZoneD) {
      mirrorValue = "D";
    }
    const userId = AuthService.getUserId();
    const emailId = AuthService.getUserName();
    const configRequest = {
      userId,
      emailId,
      configName,
      systemType: systemType.value,
      job: jobName,
      department: departmentName,
      contract: contractName,
      vehicle: vehicleName,
      vin: vinName,
      vehicleSize,
      standardName,
      isMirroredTo: mirrorValue,
      count: newLights.length ? 1 : 0,
      setBlockingLowPowerZoneA,
      setBlockingLowPowerZoneB,
      setBlockingLowPowerZoneC,
      setBlockingLowPowerZoneD,
      setBlockingIntensityZoneA,
      setBlockingIntensityZoneB,
      setBlockingIntensityZoneC,
      setBlockingIntensityZoneD,
      setCallingLowPowerZoneA,
      setCallingLowPowerZoneB,
      setCallingLowPowerZoneC,
      setCallingLowPowerZoneD,
      setCallingIntensityZoneA,
      setCallingIntensityZoneB,
      setCallingIntensityZoneC,
      setCallingIntensityZoneD,
    };
    ConfigService.saveConfigurations(configRequest)
      .then((response) => {
        this.setState({ loading: true });
        const selectedData = configRequest;
        selectedData.id = response.data.Id;
        selectedData.vehicleSize = vehicleSize;
        const requests = { configDetails: selectedData };
        requests.vehicleSize = vehicleSize;
        ConfigService.setConfigToSessionStorage(requests);
        this.setState({ selectedConfig: selectedData });
        this.setState({ configName });
        if (newLights.length) {
          ConfigService.addLightsdataToConfiguration(newLights, response.data.Id)
            .then((res) => {
              if (res && res.data) {
                this.getConfigLights();
                // *no need to call and fetch all lights as we are doing save as operation.*
                // this.getMySources(selectedData.id);
                // this.getConfigLightsByUser(selectedData.id, false);
                if (mySources.length) {
                  mySources.map((source) => {
                    const request = {
                      user_id: userId,
                      light_id: source.id,
                      configId: selectedData.id,
                      lightType: source.lightType,
                      isSavedLightSource: false,
                    };
                    ConfigService.addLightToUserPreference(request);
                  });
                }
                this.closeSaveAsModal();
                Helpers.displayNotification(SUCCESS_MESSAGE.ADD_CONFIG, "success");
              }
            })
            .catch((error) => {
              Helpers.displayErrorMessage(error);
              this.getConfigLights();
              // this.getMySources(selectedData.id);
              this.getConfigLightsByUser(selectedData.id, false);
            });
        } else {
          if (mySources.length) {
            mySources.map((source) => {
              const request = {
                user_id: userId,
                light_id: source.id,
                configId: selectedData.id,
                lightType: source.lightType,
                isSavedLightSource: false,
              };
              ConfigService.addLightToUserPreference(request);
            });
          }
          this.closeSaveAsModal();
          Helpers.displayNotification(SUCCESS_MESSAGE.ADD_CONFIG, "success");
          this.setState({ loading: false });
        }
      })
      .catch((error) => {
        this.setState({ loading: false });
        Helpers.displayErrorMessage(error);
      });
  };

  // to close share configuration modal
  closeShareConfigModal = () => {
    this.setState({ isShareConfigModal: false });
  };

  // To start share configurations process.
  shareConfiguration = (emailId) => {
    this.setState({ loading: true, isDirty: false });
    const vehicleSize = ConfigService.getVehicleSizeFromStorage();
    const newLights = this.getRequestObjectForUpdateLights();
    const {
      systemType,
      jobName,
      configName,
      departmentName,
      vehicleName,
      vinName,
      contractName,
      isMirrorToZoneB,
      isMirrorToZoneD,
      standardName,
      setBlockingLowPowerZoneA,
      setBlockingLowPowerZoneB,
      setBlockingLowPowerZoneC,
      setBlockingLowPowerZoneD,
      setBlockingIntensityZoneA,
      setBlockingIntensityZoneB,
      setBlockingIntensityZoneC,
      setBlockingIntensityZoneD,
      setCallingLowPowerZoneA,
      setCallingLowPowerZoneB,
      setCallingLowPowerZoneC,
      setCallingLowPowerZoneD,
      setCallingIntensityZoneA,
      setCallingIntensityZoneB,
      setCallingIntensityZoneC,
      setCallingIntensityZoneD,
    } = this.state;

    let mirrorValue = "";
    if (isMirrorToZoneB) {
      mirrorValue = "B";
    } else if (isMirrorToZoneD) {
      mirrorValue = "D";
    }
    const userId = AuthService.getUserId();
    const sourceEmail = AuthService.getUserName();
    const configRequest = {
      userId,
      emailId,
      sourceEmail,
      configName,
      systemType: systemType.value,
      job: jobName,
      department: departmentName,
      contract: contractName,
      vehicle: vehicleName,
      vin: vinName,
      vehicleSize,
      standardName,
      isMirroredTo: mirrorValue,
      count: newLights.length ? 1 : 0,
      setBlockingLowPowerZoneA,
      setBlockingLowPowerZoneB,
      setBlockingLowPowerZoneC,
      setBlockingLowPowerZoneD,
      setBlockingIntensityZoneA,
      setBlockingIntensityZoneB,
      setBlockingIntensityZoneC,
      setBlockingIntensityZoneD,
      setCallingLowPowerZoneA,
      setCallingLowPowerZoneB,
      setCallingLowPowerZoneC,
      setCallingLowPowerZoneD,
      setCallingIntensityZoneA,
      setCallingIntensityZoneB,
      setCallingIntensityZoneC,
      setCallingIntensityZoneD,
    };
    ConfigService.shareConfigurations(configRequest)
      .then((response) => {
        if (response?.data?.Id?.length) {
          this.setState({ loading: true });
          const selectedData = configRequest;
          selectedData.id = response.data.Id;
          selectedData.vehicleSize = vehicleSize;
          const requests = { configDetails: selectedData };
          requests.vehicleSize = vehicleSize;
          ConfigService.setConfigToSessionStorage(requests);
          this.setState({ selectedConfig: selectedData });
          if (newLights.length) {
            ConfigService.addLightsdataToConfiguration(newLights, response.data.Id)
              .then((res) => {
                if (res && res.data) {
                  this.setState({ loading: false });
                  this.closeShareConfigModal();
                  Helpers.displayNotification(SUCCESS_MESSAGE.COPY_CONFIG, "success");
                }
              })
              .catch((error) => {
                Helpers.displayErrorMessage(error);
                this.setState({ loading: false });
              });
          } else {
            this.setState({ loading: false });
            Helpers.displayNotification(SUCCESS_MESSAGE.COPY_CONFIG, "success");
            this.closeShareConfigModal();
          }
        } else {
          this.setState({ loading: false });
          Helpers.displayNotification(SUCCESS_MESSAGE.COPY_CONFIG, "success");
          this.closeShareConfigModal();
        }
      })
      .catch((error) => {
        this.setState({ loading: false });
        Helpers.displayErrorMessage(error);
      });
  };

  // to close delete configuration modal
  closeDelConfigModal = () => {
    this.setState({ isDeleteModal: false });
  };

  // To delete config
  deleteUserConfig = () => {
    const configDetails = ConfigService?.getConfigDetailsFromStorage();
    this.setState({ loading: true });

    // Call for delete configuration API
    ConfigService?.deleteConfigurationMethod(configDetails?.id)
      .then((response) => {
        if (response?.status === 200 || response?.status === 204) {
          this.setState({ loading: false });
          this.navigateToHomepage();
          this.closeDelConfigModal();
          Helpers.displayNotification(SUCCESS_MESSAGE.DELETE_CONFIG, "success");
        } else {
          this.navigateToHomepage();
          this.setState({ loading: false });
          this.closeDelConfigModal();
          Helpers.displayNotification(ERROR_MESSAGE.DELETE_FAILED, "error");
        }
      })
      .catch((error) => {
        this.setState({ loading: false });
        Helpers.displayErrorMessage(error);
      });
  };

  // used for adding lights to  Database
  addLightsToDB = async (record, zone, level) => {
    const {
      selectedConfig,
      configName,
      systemType,
      jobName,
      departmentName,
      contractName,
      vehicleName,
      vinName,
      vehicleSize,
      standardName,
      note,
      setBlockingLowPowerZoneA,
      setBlockingLowPowerZoneB,
      setBlockingLowPowerZoneC,
      setBlockingLowPowerZoneD,
      setBlockingIntensityZoneA,
      setBlockingIntensityZoneB,
      setBlockingIntensityZoneC,
      setBlockingIntensityZoneD,
      setCallingLowPowerZoneA,
      setCallingLowPowerZoneB,
      setCallingLowPowerZoneC,
      setCallingLowPowerZoneD,
      setCallingIntensityZoneA,
      setCallingIntensityZoneB,
      setCallingIntensityZoneC,
      setCallingIntensityZoneD,
    } = this.state;
    const colors = {};
    if (record.color1) {
      const color1 = {
        color: record.color1,
        call_intensity: record.call_intensity,
        block_intensity: record.block_intensity,
      };
      colors["1"] = color1;
    }
    if (record.color2) {
      const color2 = {
        color: record.color2,
        call_intensity: record.call_intensity,
        block_intensity: record.block_intensity,
      };
      colors["2"] = color2;
    }

    if (record.color3) {
      const color3 = {
        color: record.color3,
        call_intensity: record.call_intensity,
        block_intensity: record.block_intensity,
      };
      colors["3"] = color3;
    }

    if (
      record.lightType === "Lightbar" ||
      record.lightType === "ImportedLightbar" ||
      record.lightType === "Specialty"
    ) {
      const color1 = {
        color: "",
        call_intensity: record.call_intensity,
        block_intensity: record.block_intensity,
      };
      colors["1"] = color1;
    }
    // add lights when selected  configuration is available or edit configuration
    if (selectedConfig) {
      const request = {
        configId: selectedConfig && selectedConfig?.Id ? selectedConfig?.Id : selectedConfig?.id,
        zone,
        level,
        productId: record.id,
        qty: 1,
        colors,
        obstruct: record.obstruct,
        rotation: "0",
        lowPower: record.lowPower,
        lightType: record.lightType,
      };
      await ConfigService.addLightsToConfiguration(request)
        .then((response) => {
          if (response && response.data) {
            const { zoneLightsData, lightsData } = this.state;
            const newLights = response.data.params.Item[0];
            let zoneName = `zone${newLights.zone}Data`;
            if (newLights.level === "upper") {
              zoneName = `zone${newLights.zone}DataUpper`;
            } else if (newLights.level === "lower") {
              zoneName = `zone${newLights.zone}DataLower`;
            }
            const zoneData = zoneLightsData[zoneName] || [];
            const findIndex = lightsData && lightsData.findIndex((zoneA) => zoneA.id === newLights.productId);
            // For all lights from DB
            if (findIndex !== "" && findIndex !== -1) {
              const object = lightsData[findIndex];
              object.lightId = newLights.id;
              object.sDisplayName = record.sDisplayName;
              object.displayName = record.displayName;
              object.colors = newLights.colors;
              object.configId = newLights.configId;
              object.qty = newLights.qty;
              object.quantity = newLights.qty;
              object.rotation = "0";
              object.obstruct = newLights.obstruct;
              object.productId = newLights.productId;
              object.level = newLights.level;
              object.lowPower = record.lowPower;
              object.intensityOptions = record.intensityOptions;
              if (
                ((request.zone === ZONE.ZONE_A && setBlockingLowPowerZoneA) ||
                  (request.zone === ZONE.ZONE_B && setBlockingLowPowerZoneB) ||
                  (request.zone === ZONE.ZONE_C && setBlockingLowPowerZoneC) ||
                  (request.zone === ZONE.ZONE_D && setBlockingLowPowerZoneD)) &&
                record?.lowPower &&
                !ONTARIO_STANDARD.includes(standardName)
              ) {
                if (systemType.value === "STANDALONE_LIGHTS") {
                  object.block_intensity = record.intensityOptions[1].value;
                  object.block_intensity2 = record.intensityOptions[1].value;
                  object.block_intensity3 = record.intensityOptions[1].value;
                } else {
                  let lowpercentage = LOW_PERCENTAGE;
                  if (request.zone === ZONE.ZONE_A) {
                    lowpercentage = setBlockingIntensityZoneA?.value;
                  } else if (request.zone === ZONE.ZONE_B) {
                    lowpercentage = setBlockingIntensityZoneB?.value;
                  } else if (request.zone === ZONE.ZONE_C) {
                    lowpercentage = setBlockingIntensityZoneC?.value;
                  } else {
                    lowpercentage = setBlockingIntensityZoneD?.value;
                  }
                  const findRecord = record.intensityOptions?.findIndex((item) => item.value === lowpercentage);
                  if (findRecord !== -1) {
                    object.block_intensity = record.intensityOptions[findRecord]?.value;
                    object.block_intensity2 = record.intensityOptions[findRecord]?.value;
                    object.block_intensity3 = record.intensityOptions[findRecord]?.value;
                  }
                }
              } else {
                object.block_intensity = record.intensityOptions[0].value;
                object.block_intensity2 = record.intensityOptions[0].value;
                object.block_intensity3 = record.intensityOptions[0].value;
              }
              if (
                ((request.zone === ZONE.ZONE_A && setCallingLowPowerZoneA) ||
                  (request.zone === ZONE.ZONE_B && setCallingLowPowerZoneB) ||
                  (request.zone === ZONE.ZONE_C && setCallingLowPowerZoneC) ||
                  (request.zone === ZONE.ZONE_D && setCallingLowPowerZoneD)) &&
                record?.lowPower
              ) {
                if (systemType.value === "STANDALONE_LIGHTS") {
                  object.call_intensity = record.intensityOptions[1].value;
                  object.call_intensity2 = record.intensityOptions[1].value;
                  object.call_intensity3 = record.intensityOptions[1].value;
                } else {
                  let lowpercentage = LOW_PERCENTAGE;
                  if (request.zone === ZONE.ZONE_A) {
                    lowpercentage = setCallingIntensityZoneA?.value;
                  } else if (request.zone === ZONE.ZONE_B) {
                    lowpercentage = setCallingIntensityZoneB?.value;
                  } else if (request.zone === ZONE.ZONE_C) {
                    lowpercentage = setCallingIntensityZoneC?.value;
                  } else {
                    lowpercentage = setCallingIntensityZoneD?.value;
                  }
                  const findRecord = record.intensityOptions?.findIndex((item) => item.value === lowpercentage);
                  if (findRecord !== -1) {
                    object.call_intensity = record.intensityOptions[findRecord]?.value;
                    object.call_intensity2 = record.intensityOptions[findRecord]?.value;
                    object.call_intensity3 = record.intensityOptions[findRecord]?.value;
                  }
                }
              } else {
                object.call_intensity = record.intensityOptions[0].value;
                object.call_intensity2 = record.intensityOptions[0].value;
                object.call_intensity3 = record.intensityOptions[0].value;
              }
              object.displayId = record.displayId;
              object.currentAverage = record.currentAverage;
              object.currentMax = record.currentMax;
              object.tooltipData = record.tooltipData;
              object.showExlm = record.showExlm;
              object.showHash = record.showHash;
              object.zone = zone;
              zoneData[record.index] = JSON.parse(JSON.stringify(object));
              zoneLightsData[zoneName] = JSON.parse(JSON.stringify(zoneData));
              this.setState({ zoneLightsData });
              if (!ONTARIO_STANDARD.includes(standardName)) {
                setTimeout(() => {
                  this.updateLowPower();
                }, 2);
              } else {
                setTimeout(() => {
                  this.updateCallingLowPower();
                }, 2);
              }
            } else {
              const object = record;
              object.lightId = newLights.id;
              object.colors = newLights.colors;
              object.configId = newLights.configId;
              object.qty = newLights.qty;
              object.quantity = newLights.qty;
              object.rotation = "0";
              object.obstruct = newLights.obstruct;
              object.productId = newLights.productId;
              object.level = newLights.level;
              object.zone = zone;
              object.lowPower = newLights.lowPower;
              zoneData[record.index] = JSON.parse(JSON.stringify(object));
              zoneLightsData[zoneName] = JSON.parse(JSON.stringify(zoneData));
              this.setState({ zoneLightsData });
              if (!ONTARIO_STANDARD.includes(standardName)) {
                setTimeout(() => {
                  this.updateLowPower();
                }, 2);
              } else {
                setTimeout(() => {
                  this.updateCallingLowPower();
                }, 2);
              }
            }
          }
        })
        .catch(() => {
          Helpers.displayNotification(ERROR_MESSAGE.ADD_LIGHT_FAILED, "error");
          this.getConfigLights();
        });
    } else {
      // add lights when configuration is new
      const userId = AuthService.getUserId();
      const emailId = AuthService.getUserName();
      const configRequest = {
        userId,
        emailId,
        configName,
        systemType: systemType.value,
        job: jobName,
        department: departmentName,
        contract: contractName,
        vehicle: vehicleName,
        vin: vinName,
        vehicleSize,
        standardName,
        note,
        count: 1,
        setBlockingLowPowerZoneA,
        setBlockingLowPowerZoneB,
        setBlockingLowPowerZoneC,
        setBlockingLowPowerZoneD,
        setCallingLowPowerZoneA,
        setCallingLowPowerZoneB,
        setCallingLowPowerZoneC,
        setCallingLowPowerZoneD,
      };
      this.setState({ loading: true });
      ConfigService.saveConfigurations(configRequest)
        .then((configData) => {
          if (configData && configData.data) {
            const selectedData = configRequest;
            selectedData.id = configData.data.Id;
            const requests = { configDetails: selectedData, vehicleSize };
            ConfigService.setConfigToSessionStorage(requests);
            this.setState({ selectedConfig: selectedData, isDirty: false });
            this.setState({ loading: false });
            const request = {
              configId: configData.data.Id,
              zone,
              level,
              productId: record.id,
              qty: 1,
              colors,
              obstruct: record.obstruct,
              rotation: "0",
              lowPower: record.lowPower,
              lightType: record.lightType,
            };
            ConfigService.addLightsToConfiguration(request)
              .then((response) => {
                if (response && response.data) {
                  const { zoneLightsData, lightsData } = this.state;
                  const newLights = response.data.params.Item[0];
                  let zoneName = `zone${newLights.zone}Data`;
                  if (newLights.level === "upper") {
                    zoneName = `zone${newLights.zone}DataUpper`;
                  } else if (newLights.level === "lower") {
                    zoneName = `zone${newLights.zone}DataLower`;
                  }
                  const zoneData = zoneLightsData[zoneName] || [];
                  const findIndex = lightsData && lightsData.findIndex((zoneA) => zoneA.id === newLights.productId);
                  if (findIndex !== "" && findIndex !== -1) {
                    const object = lightsData[findIndex];
                    object.lightId = newLights.id;
                    object.sDisplayName = record.sDisplayName;
                    object.displayName = record.displayName;
                    object.colors = newLights.colors;
                    object.configId = newLights.configId;
                    object.qty = newLights.qty;
                    object.quantity = newLights.qty;
                    object.rotation = "0";
                    object.obstruct = newLights.obstruct;
                    object.productId = newLights.productId;
                    object.level = newLights.level;
                    object.intensityOptions = record.intensityOptions;
                    if (
                      ((request.zone === ZONE.ZONE_A && setBlockingLowPowerZoneA) ||
                        (request.zone === ZONE.ZONE_B && setBlockingLowPowerZoneB) ||
                        (request.zone === ZONE.ZONE_C && setBlockingLowPowerZoneC) ||
                        (request.zone === ZONE.ZONE_D && setBlockingLowPowerZoneD)) &&
                      record?.lowPower &&
                      !ONTARIO_STANDARD.includes(standardName)
                    ) {
                      if (systemType.value === "STANDALONE_LIGHTS") {
                        object.block_intensity = record.intensityOptions[1].value;
                        object.block_intensity2 = record.intensityOptions[1].value;
                        object.block_intensity3 = record.intensityOptions[2].value;
                      } else {
                        const findRecord = record.intensityOptions?.findIndex((item) => item.value === LOW_PERCENTAGE);
                        if (findRecord !== -1) {
                          object.block_intensity = record.intensityOptions[findRecord]?.value;
                          object.block_intensity2 = record.intensityOptions[findRecord]?.value;
                          object.block_intensity3 = record.intensityOptions[findRecord]?.value;
                        }
                      }
                    } else {
                      object.block_intensity = record.intensityOptions[0].value;
                      object.block_intensity2 = record.intensityOptions[0].value;
                      object.block_intensity3 = record.intensityOptions[0].value;
                    }
                    if (
                      ((request.zone === ZONE.ZONE_A && setCallingLowPowerZoneA) ||
                        (request.zone === ZONE.ZONE_B && setCallingLowPowerZoneB) ||
                        (request.zone === ZONE.ZONE_C && setCallingLowPowerZoneC) ||
                        (request.zone === ZONE.ZONE_D && setCallingLowPowerZoneD)) &&
                      record?.lowPower
                    ) {
                      if (systemType.value === "STANDALONE_LIGHTS") {
                        object.call_intensity = record.intensityOptions[1].value;
                        object.call_intensity2 = record.intensityOptions[1].value;
                        object.call_intensity3 = record.intensityOptions[1].value;
                      } else {
                        let lowpercentage = LOW_PERCENTAGE;
                        if (request.zone === ZONE.ZONE_A) {
                          lowpercentage = setCallingIntensityZoneA?.value;
                        } else if (request.zone === ZONE.ZONE_B) {
                          lowpercentage = setCallingIntensityZoneB?.value;
                        } else if (request.zone === ZONE.ZONE_C) {
                          lowpercentage = setCallingIntensityZoneC?.value;
                        } else {
                          lowpercentage = setCallingIntensityZoneD?.value;
                        }
                        const findRecord = record.intensityOptions?.findIndex((item) => item.value === lowpercentage);
                        if (findRecord !== -1) {
                          object.call_intensity = record.intensityOptions[findRecord]?.value;
                          object.call_intensity2 = record.intensityOptions[findRecord]?.value;
                          object.call_intensity3 = record.intensityOptions[findRecord]?.value;
                        }
                      }
                    } else {
                      object.call_intensity = record.intensityOptions[0].value;
                      object.call_intensity2 = record.intensityOptions[0].value;
                      object.call_intensity3 = record.intensityOptions[0].value;
                    }
                    object.displayId = record.displayId;
                    object.currentAverage = record.currentAverage;
                    object.currentMax = record.currentMax;
                    object.tooltipData = record.tooltipData;
                    object.showExlm = record.showExlm;
                    object.showHash = record.showHash;
                    object.zone = zone;
                    zoneData[record.index] = JSON.parse(JSON.stringify(object));
                    zoneLightsData[zoneName] = JSON.parse(JSON.stringify(zoneData));
                    this.setState({ zoneLightsData });
                    if (!ONTARIO_STANDARD.includes(standardName)) {
                      setTimeout(() => {
                        this.updateLowPower();
                      }, 1);
                    } else {
                      setTimeout(() => {
                        this.updateCallingLowPower();
                      }, 1);
                    }
                  } else {
                    const object = record;
                    object.lightId = newLights.id;
                    object.colors = newLights.colors;
                    object.configId = newLights.configId;
                    object.qty = newLights.qty;
                    object.quantity = newLights.qty;
                    object.rotation = "0";
                    object.obstruct = newLights.obstruct;
                    object.productId = newLights.productId;
                    object.level = newLights.level;
                    object.zone = zone;
                    object.lowPower = newLights.lowPower;
                    zoneData[record.index] = JSON.parse(JSON.stringify(object));
                    zoneLightsData[zoneName] = JSON.parse(JSON.stringify(zoneData));
                    this.setState({ zoneLightsData });
                    if (!ONTARIO_STANDARD.includes(standardName)) {
                      setTimeout(() => {
                        this.updateLowPower();
                      }, 1);
                    } else {
                      setTimeout(() => {
                        this.updateCallingLowPower();
                      }, 1);
                    }
                  }
                }
              })
              .catch(() => {
                Helpers.displayNotification(ERROR_MESSAGE.ADD_LIGHT_FAILED, "error");
                this.getConfigLights();
              });
          }
        })
        .catch((error) => {
          this.setState({ loading: false });
          Helpers.displayErrorMessage(error);
          this.getConfigLights();
        });
    }
  };

  // used for add mysources to db
  setMySources = async (source) => {
    const userId = AuthService.getUserId();
    const emailId = AuthService.getUserName();
    const {
      mySources,
      selectedConfig,
      configName,
      systemType,
      jobName,
      departmentName,
      contractName,
      vehicleName,
      vinName,
      vehicleSize,
      standardName,
      note,
      setBlockingLowPowerZoneA,
      setBlockingLowPowerZoneB,
      setBlockingLowPowerZoneC,
      setBlockingLowPowerZoneD,
      setCallingLowPowerZoneA,
      setCallingLowPowerZoneB,
      setCallingLowPowerZoneC,
      setCallingLowPowerZoneD,
    } = this.state;
    const sources = mySources || [];
    const index = sources.findIndex((element) => element.id === source.id);
    if (index === -1) {
      if (selectedConfig) {
        const request = {
          user_id: userId,
          light_id: source.id,
          configId: selectedConfig.id,
          lightType: source.lightType,
          isSavedLightSource: false,
        };
        await ConfigService.addLightToUserPreference(request).then((response) => {
          if (response && response.data) {
            const newSource = [source];
            newSource[0].parentId = response.data.id;
            this.setState((prevStat) => ({ mySources: [...prevStat.mySources, ...newSource] }));
          }
        });
      } else {
        const configRequest = {
          userId,
          emailId,
          configName,
          systemType: systemType.value,
          job: jobName,
          department: departmentName,
          contract: contractName,
          vehicle: vehicleName,
          vin: vinName,
          vehicleSize,
          standardName,
          note,
          setBlockingLowPowerZoneA,
          setBlockingLowPowerZoneB,
          setBlockingLowPowerZoneC,
          setBlockingLowPowerZoneD,
          setCallingLowPowerZoneA,
          setCallingLowPowerZoneB,
          setCallingLowPowerZoneC,
          setCallingLowPowerZoneD,
        };
        await ConfigService.saveConfigurations(configRequest)
          .then((configData) => {
            if (configData && configData.data) {
              const selectedData = configRequest;
              selectedData.id = configData.data.Id;
              const requests = { configDetails: selectedData };
              ConfigService.setConfigToSessionStorage(requests);
              this.setState({ selectedConfig: selectedData });
              const request = {
                user_id: userId,
                light_id: source.id,
                configId: configData.data.Id,
                lightType: source.lightType,
                isSavedLightSource: false,
              };
              ConfigService.addLightToUserPreference(request).then((response) => {
                if (response && response.data) {
                  const newSource = [source];
                  newSource[0].parentId = response.data.id;
                  this.setState((prevStat) => ({ mySources: [...prevStat.mySources, ...newSource] }));
                }
              });
            }
          })
          .catch((error) => {
            Helpers.displayErrorMessage(error);
            // this.getMySources(selectedConfig.id);
            this.getConfigLightsByUser(selectedConfig.id, false);
          });
      }
    }
  };

  // used to set mirror rotation data
  setMirrorRotationData = (value) => value * -1;

  // used to set mirror obstruct data
  setMirrorObstructData = (key, ...value) => {
    const obstValue = value;
    if (value.length > 0) {
      obstValue.map((zone, ind) => {
        if (zone === "B") {
          obstValue[ind] = "D";
        } else if (zone === "D") {
          obstValue[ind] = "B";
        }
      });
    }
    return obstValue;
  };

  // used for handle inputs data from zone
  setKeysData = (value, key, index, mainData) => {
    this.setDirty(true);
    const { zoneLightsData, isMirrorToZoneD, isMirrorToZoneB } = this.state;
    switch (mainData) {
      case "zoneADataUpper":
        zoneLightsData.zoneADataUpper[index][key] = value;
        break;
      case "zoneBDataUpper":
        if (isMirrorToZoneD) {
          // set rotation and obstruct data
          if (key === "rotation") {
            const rotationdata = this.setMirrorRotationData(value);
            zoneLightsData.zoneDDataUpper[index][key] = rotationdata;
          } else if (key === "obstruct") {
            const obstructData = this.setMirrorObstructData(key, ...value);
            zoneLightsData.zoneDDataUpper[index][key] = obstructData;
          } else {
            zoneLightsData.zoneDDataUpper[index][key] = value;
          }
        }
        zoneLightsData.zoneBDataUpper[index][key] = value;
        break;
      case "zoneCDataUpper":
        zoneLightsData.zoneCDataUpper[index][key] = value;
        break;
      case "zoneDDataUpper":
        if (isMirrorToZoneB) {
          // set rotation and obstruct data
          if (key === "rotation") {
            const rotationdata = this.setMirrorRotationData(value);
            zoneLightsData.zoneBDataUpper[index][key] = rotationdata;
          } else if (key === "obstruct") {
            const obstructData = this.setMirrorObstructData(key, ...value);
            zoneLightsData.zoneBDataUpper[index][key] = obstructData;
          } else {
            zoneLightsData.zoneBDataUpper[index][key] = value;
          }
        }
        zoneLightsData.zoneDDataUpper[index][key] = value;
        break;
      case "zoneADataLower":
        zoneLightsData.zoneADataLower[index][key] = value;
        break;
      case "zoneBDataLower":
        if (isMirrorToZoneD) {
          // set rotation and obstruct data
          if (key === "rotation") {
            const rotationdata = this.setMirrorRotationData(value);
            zoneLightsData.zoneDDataLower[index][key] = rotationdata;
          } else if (key === "obstruct") {
            const obstructData = this.setMirrorObstructData(key, ...value);
            zoneLightsData.zoneDDataLower[index][key] = obstructData;
          } else {
            zoneLightsData.zoneDDataLower[index][key] = value;
          }
        }
        zoneLightsData.zoneBDataLower[index][key] = value;
        break;
      case "zoneCDataLower":
        zoneLightsData.zoneCDataLower[index][key] = value;
        break;
      case "zoneDDataLower":
        if (isMirrorToZoneB) {
          // set rotation and obstruct data
          if (key === "rotation") {
            const rotationdata = this.setMirrorRotationData(value);
            zoneLightsData.zoneBDataLower[index][key] = rotationdata;
          } else if (key === "obstruct") {
            const obstructData = this.setMirrorObstructData(key, ...value);
            zoneLightsData.zoneBDataLower[index][key] = obstructData;
          } else {
            zoneLightsData.zoneBDataLower[index][key] = value;
          }
        }
        zoneLightsData.zoneDDataLower[index][key] = value;
        break;
      case "zoneAData":
        zoneLightsData.zoneAData[index][key] = value;
        break;
      case "zoneBData":
        if (isMirrorToZoneD) {
          // set rotation and obstruct data
          if (key === "rotation") {
            const rotationdata = this.setMirrorRotationData(value);
            zoneLightsData.zoneDData[index][key] = rotationdata;
          } else if (key === "obstruct") {
            const obstructData = this.setMirrorObstructData(key, ...value);
            zoneLightsData.zoneDData[index][key] = obstructData;
          } else {
            zoneLightsData.zoneDData[index][key] = value;
          }
        }
        zoneLightsData.zoneBData[index][key] = value;
        break;
      case "zoneCData":
        zoneLightsData.zoneCData[index][key] = value;
        break;
      case "zoneDData":
        if (isMirrorToZoneB) {
          // set rotation and obstruct data
          if (key === "rotation") {
            const rotationdata = this.setMirrorRotationData(value);
            zoneLightsData.zoneBData[index][key] = rotationdata;
          } else if (key === "obstruct") {
            const obstructData = this.setMirrorObstructData(key, ...value);
            zoneLightsData.zoneBData[index][key] = obstructData;
          } else {
            zoneLightsData.zoneBData[index][key] = value;
          }
        }
        zoneLightsData.zoneDData[index][key] = value;
        break;
      default:
        // zoneADataUpper[index].quantity = value;
        break;
    }
    this.setState({ zoneLightsData });
  };

  // used for delet lights when mirror is on
  handleDeleteMirror = async (record, zoneName, isMirrorToZoneD, zoneLightsData, isMirrorToZoneB) => {
    const mainData = zoneLightsData;
    const object = record;
    // used when user check Mirror TO D checkbox is true
    if (zoneName === "zoneBData" && isMirrorToZoneD) {
      let sources1 = mainData.zoneDData || [];
      const sourceIndex = sources1.findIndex((element) => element.id === object.id);
      if (sourceIndex !== -1) {
        await ConfigService.deleteConfigLights({ lightId: sources1[sourceIndex].lightId }).then(async () => {
          sources1 = sources1.filter((value) => value.lightId !== sources1[sourceIndex].lightId);
          mainData.zoneDData = sources1;
          this.setState({ zoneLightsData: mainData });
        });
      }
    }

    // used when user check Mirror TO D checkbox is true
    if (zoneName === "zoneDData" && isMirrorToZoneB) {
      let sources1 = mainData.zoneBData || [];
      const sourceIndex = sources1.findIndex((element) => element.id === object.id);
      if (sourceIndex !== -1) {
        await ConfigService.deleteConfigLights({ lightId: sources1[sourceIndex].lightId }).then(async () => {
          sources1 = sources1.filter((value) => value.lightId !== sources1[sourceIndex].lightId);
          mainData.zoneBData = sources1;
          this.setState({ zoneLightsData: mainData });
        });
      }
    }

    // used when user check Mirror TO D checkbox is true
    if (zoneName === "zoneBDataUpper" && isMirrorToZoneD) {
      let sources1 = mainData.zoneDDataUpper || [];
      const sourceIndex = sources1.findIndex((element) => element.id === object.id);
      if (sourceIndex !== -1) {
        await ConfigService.deleteConfigLights({ lightId: sources1[sourceIndex].lightId }).then(async () => {
          sources1 = sources1.filter((value) => value.lightId !== sources1[sourceIndex].lightId);
          mainData.zoneDDataUpper = sources1;
          this.setState({ zoneLightsData: mainData });
        });
      }
    }

    // used when user check Mirror TO D checkbox is true
    if (zoneName === "zoneDDataUpper" && isMirrorToZoneB) {
      let sources1 = mainData.zoneBDataUpper || [];
      const sourceIndex = sources1.findIndex((element) => element.id === object.id);
      if (sourceIndex !== -1) {
        await ConfigService.deleteConfigLights({ lightId: sources1[sourceIndex].lightId }).then(async () => {
          sources1 = sources1.filter((value) => value.lightId !== sources1[sourceIndex].lightId);
          mainData.zoneBDataUpper = sources1;
          this.setState({ zoneLightsData: mainData });
        });
      }
    }

    // used when user check Mirror TO D checkbox is true
    if (zoneName === "zoneBDataLower" && isMirrorToZoneD) {
      let sources1 = mainData.zoneDDataLower || [];
      const sourceIndex = sources1.findIndex((element) => element.id === object.id);
      if (sourceIndex !== -1) {
        await ConfigService.deleteConfigLights({ lightId: sources1[sourceIndex].lightId }).then(async () => {
          sources1 = sources1.filter((value) => value.lightId !== sources1[sourceIndex].lightId);
          mainData.zoneDDataLower = sources1;
          this.setState({ zoneLightsData: mainData });
        });
      }
    }

    // used when user check Mirror TO D checkbox is true
    if (zoneName === "zoneDDataLower" && isMirrorToZoneB) {
      let sources1 = mainData.zoneBDataLower || [];
      const sourceIndex = sources1.findIndex((element) => element.id === object.id);
      if (sourceIndex !== -1) {
        await ConfigService.deleteConfigLights({ lightId: sources1[sourceIndex].lightId }).then(async () => {
          sources1 = sources1.filter((value) => value.lightId !== sources1[sourceIndex].lightId);
          mainData.zoneBDataLower = sources1;
          this.setState({ zoneLightsData: mainData });
        });
      }
    }
  };

  // used for delete lights from the zone/ confuguration
  deleteLightsFromZone = async (e, record, renderData, zone) => {
    e.preventDefault();
    if (record.lightId) {
      const { zoneLightsData, isMirrorToZoneD, isMirrorToZoneB, standardName } = this.state;
      const mainData = zoneLightsData;
      this.handleDeleteMirror(record, zone, isMirrorToZoneD, mainData, isMirrorToZoneB);
      await ConfigService.deleteConfigLights({ lightId: record.lightId })
        .then(async () => {
          this.setState((prevState) => {
            const oldState = prevState;
            oldState.zoneLightsData[zone] = oldState.zoneLightsData[zone].filter(
              (value) => value.lightId !== record.lightId
            );
            return { ...oldState };
          });
          Helpers.displayNotification(SUCCESS_MESSAGE.DELETE_LIGHT, "success");
          if (!ONTARIO_STANDARD.includes(standardName)) {
            this.updateLowPower();
          } else {
            this.updateCallingLowPower();
          }
        })
        .catch(() => {
          this.getConfigLights();
        });
    } else {
      this.getConfigLights();
      Helpers.displayNotification(SUCCESS_MESSAGE.DELETE_LIGHT, "success");
    }
  };

  // used for delete lights from the common preference
  deleteLightsFromPreference = (e, record) => {
    e.preventDefault();
    const { commonlyUsed } = this.state;
    ConfigService.deleteCommonPreferenceLights({ lightId: record.parentId })
      .then(async () => {
        const findDeletedIndex = commonlyUsed.findIndex((renderRecord) => renderRecord.id === record.id);
        if (findDeletedIndex !== -1) {
          commonlyUsed.splice(findDeletedIndex, 1);
          this.setState({ commonlyUsed });
        }
      })
      .catch(() => {
        // this.getCommonlyUsedSources();
        this.getConfigLightsByUser(false, true);
      });
  };

  // used for delete lights from the my sources
  deleteFromMysources = (e, zone) => {
    e.preventDefault();
    const { selectedConfig } = this.state;
    ConfigService.deleteCommonPreferenceLights({ lightId: zone.parentId })
      .then(async () => {
        this.setState((prevStat) => {
          const filteredSources = prevStat.mySources.filter((source) => source?.id !== zone.id);
          return { mySources: filteredSources };
        });
      })
      .catch(() => {
        this.getMySources(selectedConfig.id);
        this.getConfigLightsByUser(selectedConfig.id, false);
      });
  };

  // used for add lights to the common preference
  addLightsToCommonlyUsed = async (light) => {
    const request = {
      user_id: "",
      light_id: light.id,
      lightType: light.lightType,
      isSavedLightSource: true,
    };
    ConfigService.addLightToUserPreference(request).then((response) => {
      if (response && response.data) {
        const { commonlyUsed } = this.state;
        const findIndex = commonlyUsed.findIndex((commonLights) => commonLights.id === light.id);
        if (findIndex !== -1) {
          commonlyUsed[findIndex].parentId = response.data.id;
          this.setState({ commonlyUsed });
        }
      }
    });
  };

  // used for add lights to the common preference
  updateLightsToCommonlyUsed = async (lights, id) => {
    const reqestData = [];
    lights.map((light, ind) => {
      const request = {
        id: light.parentId,
        lightId: light.id,
        lightType: light.lightType,
        isSavedLightSource: true,
        order: ind + 1,
      };
      if (light.id === id) {
        delete request.id;
      }
      reqestData.push(request);
    });

    ConfigService.updateLightToUserPreference(reqestData).then((response) => {
      if (response && response.data) {
        const lightsData = lights;
        const findIndex = lightsData.findIndex((light) => light.id === id);
        if (findIndex !== -1) {
          // checking light id in response
          const findLightIndex = response.data.newLights.findIndex((lightindex) => lightindex.lightId === id);
          if (findLightIndex !== -1) {
            lightsData[findIndex].parentId = response.data.newLights[findLightIndex].id;
            this.setState({ commonlyUsed: lightsData });
          }
        }
      }
    });
  };

  // used for ordering data in saved light sources
  reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    return result;
  };

  // used for handle drage lights for the difference sections
  onDragEnd = async (result) => {
    const {
      isMirrorToZoneD,
      isMirrorToZoneB,
      zoneLightsData,
      commonlyUsed,
      mySources,
      systemType,
      standardName,
      setBlockingLowPowerZoneA,
      setBlockingLowPowerZoneB,
      setBlockingLowPowerZoneC,
      setBlockingLowPowerZoneD,
      setBlockingIntensityZoneA,
      setBlockingIntensityZoneB,
      setBlockingIntensityZoneC,
      setBlockingIntensityZoneD,
      setCallingLowPowerZoneA,
      setCallingLowPowerZoneB,
      setCallingLowPowerZoneC,
      setCallingLowPowerZoneD,
      setCallingIntensityZoneA,
      setCallingIntensityZoneB,
      setCallingIntensityZoneC,
      setCallingIntensityZoneD,
    } = this.state;
    const { destination, source } = result;
    const mainData = zoneLightsData;
    if (!destination) {
      return;
    }

    if (destination.droppableId === source.droppableId) {
      if (destination.droppableId === "commonlyUsedLights") {
        const items = this.reorder(commonlyUsed, result.source.index, result.destination.index);
        this.setState({ commonlyUsed: items });
        await this.updateLightsToCommonlyUsed(items, "");
        return;
      }
    }

    // used for handling data for the common preference drop data
    if (destination.droppableId === "commonlyUsedLights") {
      const commonlyUsedSourceIndex = source.index;
      if (commonlyUsedSourceIndex !== -1) {
        const sources = commonlyUsed || [];
        const record = mySources[commonlyUsedSourceIndex];
        const index = sources.findIndex((element) => element.id === record.id);
        if (index === -1) {
          sources.splice(result.destination.index, 0, record);
          this.setState({ commonlyUsed: sources });
          this.updateLightsToCommonlyUsed(sources, record.id);
          // this.getMySources(selectedConfig.id);
          // this.getConfigLightsByUser(selectedConfig.id, false); // after drag drop
        }
      }
    }

    // used for handling data for the normal zone data
    if (
      destination.droppableId === "zoneContent-A" ||
      (destination.droppableId === "zoneContent-B" && !isMirrorToZoneB) ||
      destination.droppableId === "zoneContent-C" ||
      (destination.droppableId === "zoneContent-D" && !isMirrorToZoneD)
    ) {
      const zoneArea = destination.droppableId;
      const zone = zoneArea.split("-")[1];
      const commonlyUsedSourceIndex = source.index;
      if (commonlyUsedSourceIndex !== -1) {
        const zoneName = `zone${zone}Data`;
        const sources = zoneLightsData[zoneName] || [];
        let record = {};
        if (source.droppableId === "commonlyUsedLights") {
          record = commonlyUsed[commonlyUsedSourceIndex];
        } else {
          record = mySources[commonlyUsedSourceIndex];
        }
        record.displayId = zoneArea;
        record.quantity = 1;
        record.intensityOptions = this.getIntensityOptions(record.lowPower);
        if (
          ((zone === ZONE.ZONE_A && setBlockingLowPowerZoneA) ||
            (zone === ZONE.ZONE_B && setBlockingLowPowerZoneB) ||
            (zone === ZONE.ZONE_C && setBlockingLowPowerZoneC) ||
            (zone === ZONE.ZONE_D && setBlockingLowPowerZoneD)) &&
          record?.lowPower &&
          !ONTARIO_STANDARD.includes(standardName)
        ) {
          if (systemType.value === "STANDALONE_LIGHTS") {
            record.block_intensity = record.intensityOptions[1].value;
            record.block_intensity2 = record.intensityOptions[1].value;
            record.block_intensity3 = record.intensityOptions[1].value;
          } else {
            let lowpercentage = LOW_PERCENTAGE;
            if (zone === ZONE.ZONE_A) {
              lowpercentage = setBlockingIntensityZoneA?.value;
            } else if (zone === ZONE.ZONE_B) {
              lowpercentage = setBlockingIntensityZoneB?.value;
            } else if (zone === ZONE.ZONE_C) {
              lowpercentage = setBlockingIntensityZoneC?.value;
            } else {
              lowpercentage = setBlockingIntensityZoneD?.value;
            }
            const findIndex = record?.intensityOptions?.findIndex((item) => item.value === lowpercentage);
            if (findIndex !== -1) {
              record.block_intensity = record.intensityOptions[findIndex]?.value;
              record.block_intensity2 = record.intensityOptions[findIndex]?.value;
              record.block_intensity3 = record.intensityOptions[findIndex]?.value;
            }
          }
        } else {
          record.block_intensity = record.intensityOptions[0].value;
          record.block_intensity2 = record.intensityOptions[0].value;
          record.block_intensity3 = record.intensityOptions[0].value;
        }
        if (
          ((zone === ZONE.ZONE_A && setCallingLowPowerZoneA) ||
            (zone === ZONE.ZONE_B && setCallingLowPowerZoneB) ||
            (zone === ZONE.ZONE_C && setCallingLowPowerZoneC) ||
            (zone === ZONE.ZONE_D && setCallingLowPowerZoneD)) &&
          record?.lowPower
        ) {
          if (systemType.value === "STANDALONE_LIGHTS") {
            record.call_intensity = record.intensityOptions[1].value;
            record.call_intensity2 = record.intensityOptions[1].value;
            record.call_intensity3 = record.intensityOptions[1].value;
          } else {
            let lowpercentage = LOW_PERCENTAGE;
            if (zone === ZONE.ZONE_A) {
              lowpercentage = setCallingIntensityZoneA?.value;
            } else if (zone === ZONE.ZONE_B) {
              lowpercentage = setCallingIntensityZoneB?.value;
            } else if (zone === ZONE.ZONE_C) {
              lowpercentage = setCallingIntensityZoneC?.value;
            } else {
              lowpercentage = setCallingIntensityZoneD?.value;
            }
            const findIndex = record?.intensityOptions?.findIndex((item) => item.value === lowpercentage);
            if (findIndex !== -1) {
              record.call_intensity = record.intensityOptions[findIndex]?.value;
              record.call_intensity2 = record.intensityOptions[findIndex]?.value;
              record.call_intensity3 = record.intensityOptions[findIndex]?.value;
            }
          }
        } else {
          record.call_intensity = record.intensityOptions[0].value;
          record.call_intensity2 = record.intensityOptions[0].value;
          record.call_intensity3 = record.intensityOptions[0].value;
        }
        record.rotation = "0";
        record.zone = zone;
        record.level = "single";
        record.obstruct = this.getObstructData(record.zone, record.lightType);
        record.index = sources.length;
        sources.push(JSON.parse(JSON.stringify(record)));
        zoneLightsData[zoneName] = JSON.parse(JSON.stringify(sources));
        this.setState({ zoneLightsData }, async () => {
          await this.handleMirrorAction(record, zoneName, isMirrorToZoneD, mainData, isMirrorToZoneB);
          this.addLightsToDB(record, zone, "single");
        });
      }
    }

    // used for handling data for the upper zone data
    if (
      destination.droppableId === "zoneContentMix-A-upper" ||
      (destination.droppableId === "zoneContentMix-B-upper" && !isMirrorToZoneB) ||
      destination.droppableId === "zoneContentMix-C-upper" ||
      (destination.droppableId === "zoneContentMix-D-upper" && !isMirrorToZoneD)
    ) {
      const zoneArea = destination.droppableId;
      const zone = zoneArea.split("-")[1];
      const commonlyUsedSourceIndex = source.index;
      if (commonlyUsedSourceIndex !== -1) {
        const zoneName = `zone${zone}DataUpper`;
        const sources = zoneLightsData[zoneName] || [];
        let record = {};
        if (source.droppableId === "commonlyUsedLights") {
          record = commonlyUsed[commonlyUsedSourceIndex];
        } else {
          record = mySources[commonlyUsedSourceIndex];
        }
        record.displayId = zoneArea;
        record.quantity = 1;
        record.intensityOptions = this.getIntensityOptions(record.lowPower);
        if (
          ((zone === ZONE.ZONE_A && setBlockingLowPowerZoneA) ||
            (zone === ZONE.ZONE_B && setBlockingLowPowerZoneB) ||
            (zone === ZONE.ZONE_C && setBlockingLowPowerZoneC) ||
            (zone === ZONE.ZONE_D && setBlockingLowPowerZoneD)) &&
          record?.lowPower &&
          !ONTARIO_STANDARD.includes(standardName)
        ) {
          if (systemType.value === "STANDALONE_LIGHTS") {
            record.block_intensity = record.intensityOptions[1].value;
            record.block_intensity2 = record.intensityOptions[1].value;
            record.block_intensity3 = record.intensityOptions[1].value;
          } else {
            let lowpercentage = LOW_PERCENTAGE;
            if (zone === ZONE.ZONE_A) {
              lowpercentage = setBlockingIntensityZoneA?.value;
            } else if (zone === ZONE.ZONE_B) {
              lowpercentage = setBlockingIntensityZoneB?.value;
            } else if (zone === ZONE.ZONE_C) {
              lowpercentage = setBlockingIntensityZoneC?.value;
            } else {
              lowpercentage = setBlockingIntensityZoneD?.value;
            }
            const findIndex = record?.intensityOptions?.findIndex((item) => item.value === lowpercentage);
            if (findIndex !== -1) {
              record.block_intensity = record.intensityOptions[findIndex]?.value;
              record.block_intensity2 = record.intensityOptions[findIndex]?.value;
              record.block_intensity3 = record.intensityOptions[findIndex]?.value;
            }
          }
        } else {
          record.block_intensity = record.intensityOptions[0].value;
          record.block_intensity2 = record.intensityOptions[0].value;
          record.block_intensity3 = record.intensityOptions[0].value;
        }
        if (
          ((zone === ZONE.ZONE_A && setCallingLowPowerZoneA) ||
            (zone === ZONE.ZONE_B && setCallingLowPowerZoneB) ||
            (zone === ZONE.ZONE_C && setCallingLowPowerZoneC) ||
            (zone === ZONE.ZONE_D && setCallingLowPowerZoneD)) &&
          record?.lowPower
        ) {
          if (systemType.value === "STANDALONE_LIGHTS") {
            record.call_intensity = record.intensityOptions[1].value;
            record.call_intensity2 = record.intensityOptions[1].value;
            record.call_intensity3 = record.intensityOptions[1].value;
          } else {
            let lowpercentage = LOW_PERCENTAGE;
            if (zone === ZONE.ZONE_A) {
              lowpercentage = setCallingIntensityZoneA?.value;
            } else if (zone === ZONE.ZONE_B) {
              lowpercentage = setCallingIntensityZoneB?.value;
            } else if (zone === ZONE.ZONE_C) {
              lowpercentage = setCallingIntensityZoneC?.value;
            } else {
              lowpercentage = setCallingIntensityZoneD?.value;
            }
            const findIndex = record?.intensityOptions?.findIndex((item) => item.value === lowpercentage);
            if (findIndex !== -1) {
              record.call_intensity = record.intensityOptions[findIndex]?.value;
              record.call_intensity2 = record.intensityOptions[findIndex]?.value;
              record.call_intensity3 = record.intensityOptions[findIndex]?.value;
            }
          }
        } else {
          record.call_intensity = record.intensityOptions[0].value;
          record.call_intensity2 = record.intensityOptions[0].value;
          record.call_intensity3 = record.intensityOptions[0].value;
        }
        record.rotation = "0";
        record.zone = zone;
        record.level = "upper";
        record.obstruct = this.getObstructData(record.zone, record.lightType);
        record.index = sources.length;
        sources.push(JSON.parse(JSON.stringify(record)));
        zoneLightsData[zoneName] = JSON.parse(JSON.stringify(sources));
        this.setState({ zoneLightsData }, async () => {
          await this.handleMirrorAction(record, zoneName, isMirrorToZoneD, zoneLightsData, isMirrorToZoneB);
          this.addLightsToDB(record, zone, "upper");
        });
      }
    }
    // used for handling data for the lower zone data
    if (
      destination.droppableId === "zoneContentMix-A-lower" ||
      (destination.droppableId === "zoneContentMix-B-lower" && !isMirrorToZoneB) ||
      destination.droppableId === "zoneContentMix-C-lower" ||
      (destination.droppableId === "zoneContentMix-D-lower" && !isMirrorToZoneD)
    ) {
      const zoneArea = destination.droppableId;
      const zone = zoneArea.split("-")[1];
      const commonlyUsedSourceIndex = source.index;
      if (commonlyUsedSourceIndex !== -1) {
        const zoneName = `zone${zone}DataLower`;
        const sources = zoneLightsData[zoneName] || [];
        let record = {};
        if (source.droppableId === "commonlyUsedLights") {
          record = commonlyUsed[commonlyUsedSourceIndex];
        } else {
          record = mySources[commonlyUsedSourceIndex];
        }
        record.displayId = zoneArea;
        record.quantity = 1;
        record.intensityOptions = this.getIntensityOptions(record.lowPower);
        if (
          ((zone === ZONE.ZONE_A && setBlockingLowPowerZoneA) ||
            (zone === ZONE.ZONE_B && setBlockingLowPowerZoneB) ||
            (zone === ZONE.ZONE_C && setBlockingLowPowerZoneC) ||
            (zone === ZONE.ZONE_D && setBlockingLowPowerZoneD)) &&
          record?.lowPower &&
          !ONTARIO_STANDARD.includes(standardName)
        ) {
          if (systemType.value === "STANDALONE_LIGHTS") {
            record.block_intensity = record.intensityOptions[1].value;
            record.block_intensity2 = record.intensityOptions[1].value;
            record.block_intensity3 = record.intensityOptions[1].value;
          } else {
            let lowpercentage = LOW_PERCENTAGE;
            if (zone === ZONE.ZONE_A) {
              lowpercentage = setBlockingIntensityZoneA?.value;
            } else if (zone === ZONE.ZONE_B) {
              lowpercentage = setBlockingIntensityZoneB?.value;
            } else if (zone === ZONE.ZONE_C) {
              lowpercentage = setBlockingIntensityZoneC?.value;
            } else {
              lowpercentage = setBlockingIntensityZoneD?.value;
            }
            const findIndex = record?.intensityOptions?.findIndex((item) => item.value === lowpercentage);
            if (findIndex !== -1) {
              record.block_intensity = record.intensityOptions[findIndex]?.value;
              record.block_intensity2 = record.intensityOptions[findIndex]?.value;
              record.block_intensity3 = record.intensityOptions[findIndex]?.value;
            }
          }
        } else {
          record.block_intensity = record.intensityOptions[0].value;
          record.block_intensity2 = record.intensityOptions[0].value;
          record.block_intensity3 = record.intensityOptions[0].value;
        }
        if (
          ((zone === ZONE.ZONE_A && setCallingLowPowerZoneA) ||
            (zone === ZONE.ZONE_B && setCallingLowPowerZoneB) ||
            (zone === ZONE.ZONE_C && setCallingLowPowerZoneC) ||
            (zone === ZONE.ZONE_D && setCallingLowPowerZoneD)) &&
          record?.lowPower
        ) {
          if (systemType.value === "STANDALONE_LIGHTS") {
            record.call_intensity = record.intensityOptions[1].value;
            record.call_intensity2 = record.intensityOptions[1].value;
            record.call_intensity3 = record.intensityOptions[1].value;
          } else {
            let lowpercentage = LOW_PERCENTAGE;
            if (zone === ZONE.ZONE_A) {
              lowpercentage = setCallingIntensityZoneA?.value;
            } else if (zone === ZONE.ZONE_B) {
              lowpercentage = setCallingIntensityZoneB?.value;
            } else if (zone === ZONE.ZONE_C) {
              lowpercentage = setCallingIntensityZoneC?.value;
            } else {
              lowpercentage = setCallingIntensityZoneD?.value;
            }
            const findIndex = record?.intensityOptions?.findIndex((item) => item.value === lowpercentage);
            if (findIndex !== -1) {
              record.call_intensity = record.intensityOptions[findIndex]?.value;
              record.call_intensity2 = record.intensityOptions[findIndex]?.value;
              record.call_intensity3 = record.intensityOptions[findIndex]?.value;
            }
          }
        } else {
          record.call_intensity = record.intensityOptions[0].value;
          record.call_intensity2 = record.intensityOptions[0].value;
          record.call_intensity3 = record.intensityOptions[0].value;
        }
        record.rotation = "0";
        record.zone = zone;
        record.level = "lower";
        record.obstruct = this.getObstructData(record.zone, record.lightType);
        record.index = sources.length;
        record.rotation = "0";
        sources.push(JSON.parse(JSON.stringify(record)));
        zoneLightsData[zoneName] = JSON.parse(JSON.stringify(sources));
        this.setState({ zoneLightsData }, async () => {
          await this.handleMirrorAction(record, zoneName, isMirrorToZoneD, zoneLightsData, isMirrorToZoneB);
          this.addLightsToDB(record, zone, "lower");
        });
      }
    }
  };

  handleMirrorAction = (record, zoneName, isMirrorToZoneD, zoneLightsData, isMirrorToZoneB) => {
    const mainData = zoneLightsData;
    const object = record;
    // used when user check Mirror TO D checkbox is true
    if (zoneName === "zoneBData" && isMirrorToZoneD) {
      const sources1 = mainData.zoneDData || [];
      object.zone = "D";
      this.addLightsToDB(object, "D", "single");
      sources1.push(JSON.parse(JSON.stringify(object)));
      mainData.zoneDData = JSON.parse(JSON.stringify(sources1));
      this.setState({ zoneLightsData: mainData });
    }

    // used when user check Mirror TO D checkbox is true
    if (zoneName === "zoneDData" && isMirrorToZoneB) {
      const sources1 = mainData.zoneBData || [];
      object.zone = "B";
      this.addLightsToDB(object, "B", "single");
      sources1.push(JSON.parse(JSON.stringify(object)));
      mainData.zoneBData = JSON.parse(JSON.stringify(sources1));
      this.setState({ zoneLightsData: mainData });
    }

    // used when user check Mirror TO D checkbox is true
    if (zoneName === "zoneBDataUpper" && isMirrorToZoneD) {
      const sources1 = mainData.zoneDDataUpper || [];
      object.zone = "D";
      this.addLightsToDB(object, "D", "upper");
      sources1.push(JSON.parse(JSON.stringify(object)));
      mainData.zoneDDataUpper = JSON.parse(JSON.stringify(sources1));
      this.setState({ zoneLightsData: mainData });
    }

    // used when user check Mirror TO D checkbox is true
    if (zoneName === "zoneDDataUpper" && isMirrorToZoneB) {
      const sources1 = mainData.zoneBDataUpper || [];
      object.zone = "B";
      this.addLightsToDB(object, "B", "upper");
      sources1.push(JSON.parse(JSON.stringify(object)));
      mainData.zoneBDataUpper = JSON.parse(JSON.stringify(sources1));
      this.setState({ zoneLightsData: mainData });
    }

    // used when user check Mirror TO D checkbox is true
    if (zoneName === "zoneBDataLower" && isMirrorToZoneD) {
      const sources1 = mainData.zoneDDataLower || [];
      object.zone = "D";
      this.addLightsToDB(object, "D", "lower");
      sources1.push(JSON.parse(JSON.stringify(object)));
      mainData.zoneDDataLower = JSON.parse(JSON.stringify(sources1));
      this.setState({ zoneLightsData: mainData });
    }

    // used when user check Mirror TO D checkbox is true
    if (zoneName === "zoneDDataLower" && isMirrorToZoneB) {
      const sources1 = mainData.zoneBDataLower || [];
      object.zone = "B";
      this.addLightsToDB(object, "B", "lower");
      sources1.push(JSON.parse(JSON.stringify(object)));
      mainData.zoneBDataLower = JSON.parse(JSON.stringify(sources1));
      this.setState({ zoneLightsData: mainData });
    }
  };

  // used for set job name
  setJobName = (value) => {
    this.setState({ jobName: value });
  };

  // used for set department name
  setDepartmentName = (value) => {
    this.setState({ departmentName: value });
  };

  // used for set contract name
  setContractName = (value) => {
    this.setState({ contractName: value });
  };

  // used for set vehicle name
  setVehicleName = (value) => {
    this.setState({ vehicleName: value });
  };

  // used for set VIN name
  setVinName = (value) => {
    this.setState({ vinName: value });
  };

  // used for set config name
  setConfigName = (value) => {
    this.setState({ configName: value });
    this.setDirty(true);
  };

  // used for set note
  setNote = (value) => {
    this.setState({ note: value });
  };

  // used for update confiugration
  updateConfiguration = async (selectedConfig, prePdfCal, action, useInactiveStatus = false) => {
    const { loading } = this.state;
    if (loading) {
      const {
        configName,
        systemType,
        jobName,
        departmentName,
        vehicleName,
        vinName,
        contractName,
        isMirrorToZoneB,
        isMirrorToZoneD,
        note,
        standardName,
        setBlockingLowPowerZoneA,
        setBlockingLowPowerZoneB,
        setBlockingLowPowerZoneC,
        setBlockingLowPowerZoneD,
        setBlockingIntensityZoneA,
        setBlockingIntensityZoneB,
        setBlockingIntensityZoneC,
        setBlockingIntensityZoneD,
        setCallingLowPowerZoneA,
        setCallingLowPowerZoneB,
        setCallingLowPowerZoneC,
        setCallingLowPowerZoneD,
        setCallingIntensityZoneA,
        setCallingIntensityZoneB,
        setCallingIntensityZoneC,
        setCallingIntensityZoneD,
      } = this.state;

      let mirrorValue = "";
      if (isMirrorToZoneB) {
        mirrorValue = "B";
      } else if (isMirrorToZoneD) {
        mirrorValue = "D";
      }
      const vehicleSize = ConfigService.getVehicleSizeFromStorage();
      const newLights = this.getRequestObjectForUpdateLights();
      if (!newLights) {
        this.setState({ loading: false });
        Helpers.displayNotification(ERROR_MESSAGE.INVALID_QTY, "error");
        return;
      }
      const userId = AuthService.getUserId();
      const emailId = AuthService.getUserName();
      const configRequest = {
        userId,
        emailId,
        configName,
        systemType: systemType.value,
        job: jobName,
        department: departmentName,
        contract: contractName,
        vehicle: vehicleName,
        vin: vinName,
        isMirroredTo: mirrorValue,
        note,
        count: this.getZoneLightCount(),
        standardName,
        setBlockingLowPowerZoneA,
        setBlockingLowPowerZoneB,
        setBlockingLowPowerZoneC,
        setBlockingLowPowerZoneD,
        setBlockingIntensityZoneA,
        setBlockingIntensityZoneB,
        setBlockingIntensityZoneC,
        setBlockingIntensityZoneD,
        setCallingLowPowerZoneA,
        setCallingLowPowerZoneB,
        setCallingLowPowerZoneC,
        setCallingLowPowerZoneD,
        setCallingIntensityZoneA,
        setCallingIntensityZoneB,
        setCallingIntensityZoneC,
        setCallingIntensityZoneD,
      };
      const id = selectedConfig && selectedConfig?.Id ? selectedConfig?.Id : selectedConfig?.id;
      ConfigService.updateConfigurations(configRequest, id)
        .then(() => {
          const selectedData = configRequest;
          selectedData.id = selectedConfig && selectedConfig?.Id ? selectedConfig?.Id : selectedConfig?.id;
          selectedData.vehicleSize = vehicleSize;
          const requests = { configDetails: selectedData };
          requests.vehicleSize = vehicleSize;
          ConfigService.setConfigToSessionStorage(requests);
          this.setState({ selectedConfig: selectedData });
          if (newLights?.length) {
            ConfigService.updateLightsToConfiguration(newLights, id)
              .then((res) => {
                if (res && res.data) {
                  if (!prePdfCal) {
                    Helpers.displayNotification(SUCCESS_MESSAGE.UPDATE_CONFIG, "success");
                    this.setState({ loading: false, isDirty: false });
                    if (action) {
                      this.selectNextAction(action);
                    }
                  } else {
                    this.getPdfResultData(selectedData.id, useInactiveStatus);
                  }
                }
              })
              .catch((error) => {
                this.setState({ loading: false });
                Helpers.displayErrorMessage(error);
              });
          } else if (!prePdfCal) {
            Helpers.displayNotification(SUCCESS_MESSAGE.UPDATE_CONFIG, "success");
            this.setState({ loading: false });
            if (action) {
              this.selectNextAction(action);
            }
          } else {
            this.getPdfResultData(selectedData.id, useInactiveStatus);
          }
        })
        .catch((error) => {
          this.setState({ loading: false });
          Helpers.displayErrorMessage(error);
        });
    }
  };

  // used for confiugration details
  updateConfigurationDetails = async (changes, selectedConfig) => {
    const { loading } = this.state;
    if (loading) {
      const {
        configName,
        isMirrorToZoneB,
        isMirrorToZoneD,
        standardName,
        setBlockingLowPowerZoneA,
        setBlockingLowPowerZoneB,
        setBlockingLowPowerZoneC,
        setBlockingLowPowerZoneD,
        setBlockingIntensityZoneA,
        setBlockingIntensityZoneB,
        setBlockingIntensityZoneC,
        setBlockingIntensityZoneD,
        setCallingLowPowerZoneA,
        setCallingLowPowerZoneB,
        setCallingLowPowerZoneC,
        setCallingLowPowerZoneD,
        setCallingIntensityZoneA,
        setCallingIntensityZoneB,
        setCallingIntensityZoneC,
        setCallingIntensityZoneD,
      } = this.state;
      let mirrorValue = "";
      if (isMirrorToZoneB) {
        mirrorValue = "B";
      } else if (isMirrorToZoneD) {
        mirrorValue = "D";
      }
      await this.setIntensityOptions(changes?.sysType?.value);
      const userId = AuthService.getUserId();
      const emailId = AuthService.getUserName();
      const configRequest = {
        userId,
        emailId,
        configName,
        systemType: changes?.sysType?.value,
        job: changes.job,
        department: changes.dept,
        contract: changes.contName,
        vehicle: changes.vclName,
        vin: changes.vnName,
        isMirroredTo: mirrorValue,
        note: changes.noteData,
        count: this.getZoneLightCount(),
        standardName,
        setBlockingLowPowerZoneA,
        setBlockingLowPowerZoneB,
        setBlockingLowPowerZoneC,
        setBlockingLowPowerZoneD,
        setBlockingIntensityZoneA,
        setBlockingIntensityZoneB,
        setBlockingIntensityZoneC,
        setBlockingIntensityZoneD,
        setCallingLowPowerZoneA,
        setCallingLowPowerZoneB,
        setCallingLowPowerZoneC,
        setCallingLowPowerZoneD,
        setCallingIntensityZoneA,
        setCallingIntensityZoneB,
        setCallingIntensityZoneC,
        setCallingIntensityZoneD,
      };
      if (changes?.imageDetail?.companyLogo) {
        configRequest.companyLogo = changes?.imageDetail?.companyLogo;
        configRequest.fileName = changes?.imageDetail?.fileName;
      } else {
        configRequest.companyLogo = "";
        configRequest.fileName = "";
      }
      const vehicleSize = ConfigService.getVehicleSizeFromStorage();
      if (selectedConfig) {
        const newLights = this.getRequestObjectForUpdateLights();
        if (!newLights) {
          this.setState({ loading: false });
          Helpers.displayNotification(ERROR_MESSAGE.INVALID_QTY, "error");
          return;
        }
        const id = selectedConfig && selectedConfig?.Id ? selectedConfig?.Id : selectedConfig?.id;
        ConfigService.updateConfigurations(configRequest, id)
          .then(() => {
            const selectedData = configRequest;
            selectedData.id = id;
            selectedData.vehicleSize = vehicleSize;
            const requests = { configDetails: selectedData };
            requests.vehicleSize = vehicleSize;
            ConfigService.setConfigToSessionStorage(requests);
            if (newLights?.length) {
              ConfigService.updateLightsToConfiguration(newLights, id)
                .then((res) => {
                  if (res && res.data) {
                    Helpers.displayNotification(SUCCESS_MESSAGE.UPDATE_CONFIG, "success");
                    this.closeConfigDetailPopup();
                    this.setConfDetailInfo(selectedData);
                    this.setState({ isDirty: false });
                  }
                })
                .catch((error) => {
                  this.setState({ loading: false });
                  Helpers.displayErrorMessage(error);
                });
            } else {
              Helpers.displayNotification(SUCCESS_MESSAGE.UPDATE_CONFIG, "success");
              this.closeConfigDetailPopup();
              this.setConfDetailInfo(selectedData);
            }
          })
          .catch((error) => {
            this.setState({ loading: false });
            Helpers.displayErrorMessage(error);
          });
      } else {
        ConfigService.saveConfigurations(configRequest) // create config
          .then((response) => {
            const selectedData = configRequest;
            selectedData.id = response.data.Id;
            selectedData.vehicleSize = vehicleSize;
            const requests = { configDetails: selectedData, vehicleSize };
            ConfigService.setConfigToSessionStorage(requests);
            this.setConfDetailInfo(selectedData);
            Helpers.displayNotification(SUCCESS_MESSAGE.UPDATE_CONFIG, "success");
            this.closeConfigDetailPopup();
            this.setState({ isDirty: false });
          })
          .catch((error) => {
            this.setState({ loading: false });
            Helpers.displayErrorMessage(error);
          });
      }
    }
  };

  setConfDetailInfo = (selectedData) => {
    this.setState({
      selectedConfig: selectedData,
      systemType: selectedData.systemType === "STANDALONE_LIGHTS" ? systemTypes[0] : systemTypes[1],
      jobName: selectedData.job,
      departmentName: selectedData.department,
      contractName: selectedData.contract,
      vehicleName: selectedData.vehicle,
      vinName: selectedData.vin,
      isMirrorToZoneD: selectedData ? selectedData.isMirroredTo === "D" : false,
      isMirrorToZoneB: selectedData ? selectedData.isMirroredTo === "B" : false,
      note: selectedData.note,
      loading: false,
      setBlockingLowPowerZoneA:
        selectedData && selectedData?.setBlockingLowPowerZoneA ? selectedData.setBlockingLowPowerZoneA : false,
      setBlockingLowPowerZoneB:
        selectedData && selectedData?.setBlockingLowPowerZoneB ? selectedData.setBlockingLowPowerZoneB : false,
      setBlockingLowPowerZoneC:
        selectedData && selectedData?.setBlockingLowPowerZoneC ? selectedData.setBlockingLowPowerZoneC : false,
      setBlockingLowPowerZoneD:
        selectedData && selectedData?.setBlockingLowPowerZoneD ? selectedData.setBlockingLowPowerZoneD : false,
      setCallingLowPowerZoneA:
        selectedData && selectedData?.setCallingLowPowerZoneA ? selectedData.setCallingLowPowerZoneA : false,
      setCallingLowPowerZoneB:
        selectedData && selectedData?.setCallingLowPowerZoneB ? selectedData.setCallingLowPowerZoneB : false,
      setCallingLowPowerZoneC:
        selectedData && selectedData?.setCallingLowPowerZoneC ? selectedData.setCallingLowPowerZoneC : false,
      setCallingLowPowerZoneD:
        selectedData && selectedData?.setCallingLowPowerZoneD ? selectedData.setCallingLowPowerZoneD : false,
    });
  };

  getRequestObjectForUpdateLights = () => {
    const { zoneLightsData } = this.state;
    const light = [];
    for (const [, value] of Object.entries(zoneLightsData)) {
      value.forEach((lgt) => {
        const obj = {};
        const colors = {};
        if (lgt.color1) {
          const color1 = {
            color: lgt.color1,
            call_intensity: lgt.call_intensity,
            block_intensity: lgt.block_intensity,
          };
          colors["1"] = color1;
        }
        if (lgt.color2) {
          const color2 = {
            color: lgt.color2,
            call_intensity: lgt.call_intensity2,
            block_intensity: lgt.block_intensity2,
          };
          colors["2"] = color2;
        }

        if (lgt.color3) {
          const color3 = {
            color: lgt.color3,
            call_intensity: lgt.call_intensity3,
            block_intensity: lgt.block_intensity3,
          };
          colors["3"] = color3;
        }
        if (lgt.lightType === "Lightbar" || lgt.lightType === "ImportedLightbar" || lgt.lightType === "Specialty") {
          const color1 = {
            color: "",
            call_intensity: lgt.call_intensity,
            block_intensity: lgt.block_intensity,
          };
          colors["1"] = color1;
        }
        obj.id = lgt.lightId;
        obj.zone = lgt.zone;
        obj.level = lgt.level;
        obj.productId = lgt.id;
        obj.qty = lgt.quantity;
        obj.colors = colors;
        obj.obstruct = lgt.obstruct ? lgt.obstruct : [];
        obj.rotation = lgt.rotation;
        obj.lowPower = lgt.lowPower;
        obj.lightType = lgt.lightType;
        light.push(obj);
      });
    }
    let correctQty = true;
    light.forEach((lt) => {
      if (!lt.qty) {
        correctQty = false;
      }
    });
    return !correctQty ? correctQty : light;
  };

  mirrorToZoneD = async () => {
    this.setDirty(true);
    const {
      isMirrorToZoneD,
      isGreaterThan,
      selectedConfig,
      zoneLightsData,
      setBlockingLowPowerZoneB,
      setBlockingIntensityZoneB,
      setCallingLowPowerZoneB,
      setCallingIntensityZoneB,
      enableLowPowerZoneB,
    } = this.state;
    const mainData = zoneLightsData;
    if (!isMirrorToZoneD === true) {
      if (isGreaterThan) {
        let { zoneDDataUpper, zoneDDataLower } = zoneLightsData;
        const { zoneBDataLower, zoneBDataUpper } = zoneLightsData;
        const zoneDataLower = zoneDDataLower;
        const zoneDataUpper = zoneDDataUpper;
        zoneDataLower.map(async (deleteZone) => {
          await ConfigService.deleteConfigLights({ lightId: deleteZone.lightId });
        });
        zoneDataUpper.map(async (deleteZone) => {
          await ConfigService.deleteConfigLights({ lightId: deleteZone.lightId });
        });
        zoneDDataUpper = JSON.parse(JSON.stringify(zoneBDataUpper));
        zoneDDataLower = JSON.parse(JSON.stringify(zoneBDataLower));
        const upperDetails = await zoneDDataUpper.map(async (upperData) => {
          const object = upperData;
          // set mirror obstruct data
          if (object.obstruct.length > 0) {
            object.obstruct = this.setMirrorObstructData("obstruct", ...object.obstruct);
          }
          // set mirror rotation data
          if (object.rotation !== "None") {
            object.rotation = this.setMirrorRotationData(object.rotation);
          }

          const request = {
            configId: selectedConfig && selectedConfig?.Id ? selectedConfig?.Id : selectedConfig?.id,
            zone: "D",
            level: upperData.level || "upper",
            productId: upperData.id,
            qty: upperData.qty || upperData.quantity,
            colors: upperData.colors,
            obstruct: upperData.obstruct,
            rotation: upperData.rotation,
            lowPower: upperData.lowPower,
            lightType: upperData.lightType,
          };
          await ConfigService.addLightsToConfiguration(request).then((response) => {
            if (response && response.data) {
              const newLights = response.data.params.Item[0];
              object.lightId = newLights.id;
              object.zone = "D";
            }
          });
          return object;
        });

        Promise.all(upperDetails).then((results) => {
          mainData.zoneDDataUpper = results;
          this.setState({ zoneLightsData: mainData });
        });
        const lowerDetails = await zoneDDataLower.map(async (lowerData) => {
          const object = lowerData;
          // set mirror obstruct data
          if (object.obstruct.length > 0) {
            object.obstruct = this.setMirrorObstructData("obstruct", ...object.obstruct);
          }
          // set mirror rotation data
          if (object.rotation !== "None") {
            object.rotation = this.setMirrorRotationData(object.rotation);
          }
          const request = {
            configId: selectedConfig && selectedConfig?.Id ? selectedConfig?.Id : selectedConfig?.id,
            zone: "D",
            level: lowerData.level || "lower",
            productId: lowerData.id,
            qty: lowerData.qty || lowerData.quantity,
            colors: lowerData.colors,
            obstruct: lowerData.obstruct,
            rotation: lowerData.rotation,
            lowPower: lowerData.lowPower,
            lightType: lowerData.lightType,
          };
          await ConfigService.addLightsToConfiguration(request).then((response) => {
            if (response && response.data) {
              const newLights = response.data.params.Item[0];
              object.lightId = newLights.id;
              object.zone = "D";
            }
          });
          return object;
        });
        Promise.all(lowerDetails).then((results) => {
          mainData.zoneDDataLower = results;
          this.setState({ zoneLightsData: mainData });
        });
        zoneLightsData.zoneDDataLower = JSON.parse(JSON.stringify(zoneDDataLower));
        zoneLightsData.zoneDDataUpper = JSON.parse(JSON.stringify(zoneDDataUpper));
        const mirrorsData = {
          isMirrorToZoneD: !isMirrorToZoneD,
          isMirrorToZoneB: false,
        };
        await ConfigService.setMirrorZoneDetailsToStorage(mirrorsData);
        this.setState({
          isMirrorToZoneD: !isMirrorToZoneD,
          isMirrorToZoneB: false,
          zoneLightsData: mainData,
          setBlockingLowPowerZoneD: setBlockingLowPowerZoneB,
          setBlockingIntensityZoneD: setBlockingIntensityZoneB,
          setCallingLowPowerZoneD: setCallingLowPowerZoneB,
          setCallingIntensityZoneD: setCallingIntensityZoneB,
          enableLowPowerZoneD: enableLowPowerZoneB,
        });
      } else {
        let { zoneDData } = zoneLightsData;
        const { zoneBData } = zoneLightsData;
        const zoneData = zoneDData;
        zoneData.map(async (deleteZone) => {
          await ConfigService.deleteConfigLights({ lightId: deleteZone.lightId });
        });
        zoneDData = JSON.parse(JSON.stringify(zoneBData));
        const data = zoneDData.map(async (zoneMainData) => {
          const object = zoneMainData;
          // set obstruct data
          if (object.obstruct.length > 0) {
            object.obstruct = this.setMirrorObstructData("obstruct", ...object.obstruct);
          }
          // set mirror rotation data
          if (object.rotation !== "None") {
            object.rotation = this.setMirrorRotationData(object.rotation);
          }
          const request = {
            configId: selectedConfig && selectedConfig?.Id ? selectedConfig?.Id : selectedConfig?.id,
            zone: "D",
            level: "single",
            productId: zoneMainData.id,
            qty: zoneMainData.qty || zoneMainData.quantity,
            colors: zoneMainData.colors,
            obstruct: zoneMainData.obstruct,
            rotation: zoneMainData.rotation,
            lowPower: zoneMainData.lowPower,
            lightType: zoneMainData.lightType,
          };
          await ConfigService.addLightsToConfiguration(request).then((response) => {
            if (response && response.data) {
              const newLights = response.data.params.Item[0];
              object.lightId = newLights.id;
              object.zone = "D";
            }
          });
          return object;
        });
        Promise.all(data).then((results) => {
          mainData.zoneDData = results;
          this.setState({ zoneLightsData: mainData });
        });
        zoneLightsData.zoneDData = JSON.parse(JSON.stringify(zoneDData));
        const mirrorsData = {
          isMirrorToZoneD: !isMirrorToZoneD,
          isMirrorToZoneB: false,
        };
        await ConfigService.setMirrorZoneDetailsToStorage(mirrorsData);
        this.setState({
          isMirrorToZoneD: !isMirrorToZoneD,
          isMirrorToZoneB: false,
          zoneLightsData: mainData,
          setBlockingLowPowerZoneD: setBlockingLowPowerZoneB,
          setBlockingIntensityZoneD: setBlockingIntensityZoneB,
          setCallingLowPowerZoneD: setCallingLowPowerZoneB,
          setCallingIntensityZoneD: setCallingIntensityZoneB,
          enableLowPowerZoneD: enableLowPowerZoneB,
        });
      }
    } else {
      const mirrorsData = {
        isMirrorToZoneB: false,
        isMirrorToZoneD: false,
      };
      await ConfigService.setMirrorZoneDetailsToStorage(mirrorsData);
      this.setState({
        isMirrorToZoneD: !isMirrorToZoneD,
        isMirrorToZoneB: false,
        enableLowPowerZoneD: enableLowPowerZoneB,
      });
    }
  };

  mirrorToZoneB = async () => {
    this.setDirty(true);
    const {
      isMirrorToZoneB,
      isGreaterThan,
      zoneLightsData,
      selectedConfig,
      setBlockingLowPowerZoneD,
      setBlockingIntensityZoneD,
      setCallingLowPowerZoneD,
      setCallingIntensityZoneD,
      enableLowPowerZoneD,
    } = this.state;
    const mainData = zoneLightsData;
    if (!isMirrorToZoneB === true) {
      if (isGreaterThan) {
        const { zoneDDataUpper, zoneDDataLower } = zoneLightsData;
        let { zoneBDataLower, zoneBDataUpper } = zoneLightsData;
        const zoneDataLower = zoneBDataLower;
        const zoneDataUpper = zoneBDataUpper;
        zoneDataLower.map(async (deleteZone) => {
          await ConfigService.deleteConfigLights({ lightId: deleteZone.lightId });
        });
        zoneDataUpper.map(async (deleteZone) => {
          await ConfigService.deleteConfigLights({ lightId: deleteZone.lightId });
        });
        zoneBDataUpper = JSON.parse(JSON.stringify(zoneDDataUpper));
        zoneBDataLower = JSON.parse(JSON.stringify(zoneDDataLower));
        const upperdetails = zoneBDataUpper.map(async (upperData) => {
          const object = upperData;
          // set mirror obstruct data
          if (object.obstruct.length > 0) {
            object.obstruct = this.setMirrorObstructData("obstruct", ...object.obstruct);
          }
          // set mirror rotation data
          if (object.rotation !== "None") {
            object.rotation = this.setMirrorRotationData(object.rotation);
          }
          const request = {
            configId: selectedConfig && selectedConfig?.Id ? selectedConfig?.Id : selectedConfig?.id,
            zone: "B",
            level: upperData.level || "upper",
            productId: upperData.id,
            qty: upperData.qty || upperData.quantity,
            colors: upperData.colors,
            obstruct: upperData.obstruct,
            rotation: upperData.rotation,
            lowPower: upperData.lowPower,
            lightType: upperData.lightType,
          };
          await ConfigService.addLightsToConfiguration(request).then((response) => {
            if (response && response.data) {
              const newLights = response.data.params.Item[0];
              object.lightId = newLights.id;
              object.zone = "B";
            }
          });
          return object;
        });
        Promise.all(upperdetails).then((results) => {
          mainData.zoneBDataUpper = results;
          this.setState({ zoneLightsData: mainData });
        });
        const lowerDetails = zoneBDataLower.map(async (lowerData) => {
          const object = lowerData;
          // set mirror obstruct data
          if (object.obstruct.length > 0) {
            object.obstruct = this.setMirrorObstructData("obstruct", ...object.obstruct);
          }
          // set mirror rotation data
          if (object.rotation !== "None") {
            object.rotation = this.setMirrorRotationData(object.rotation);
          }
          const request = {
            configId: selectedConfig && selectedConfig?.Id ? selectedConfig?.Id : selectedConfig?.id,
            zone: "B",
            level: lowerData.level || "lower",
            productId: lowerData.id,
            qty: lowerData.qty || lowerData.quantity,
            colors: lowerData.colors,
            obstruct: lowerData.obstruct,
            rotation: lowerData.rotation,
            lowPower: lowerData.lowPower,
            lightType: lowerData.lightType,
          };
          await ConfigService.addLightsToConfiguration(request).then((response) => {
            if (response && response.data) {
              const newLights = response.data.params.Item[0];
              object.lightId = newLights.id;
              object.zone = "B";
            }
          });
          return object;
        });
        Promise.all(lowerDetails).then((results) => {
          mainData.zoneBDataLower = results;
          this.setState({ zoneLightsData: mainData });
        });
        zoneLightsData.zoneBDataLower = JSON.parse(JSON.stringify(zoneBDataLower));
        zoneLightsData.zoneBDataUpper = JSON.parse(JSON.stringify(zoneBDataUpper));
        const mirrorsData = {
          isMirrorToZoneB: !isMirrorToZoneB,
          isMirrorToZoneD: false,
        };
        await ConfigService.setMirrorZoneDetailsToStorage(mirrorsData);
        this.setState({
          isMirrorToZoneB: !isMirrorToZoneB,
          isMirrorToZoneD: false,
          zoneLightsData: mainData,
          setBlockingLowPowerZoneB: setBlockingLowPowerZoneD,
          setBlockingIntensityZoneB: setBlockingIntensityZoneD,
          setCallingLowPowerZoneB: setCallingLowPowerZoneD,
          setCallingIntensityZoneB: setCallingIntensityZoneD,
          enableLowPowerZoneB: enableLowPowerZoneD,
        });
      } else {
        const { zoneDData } = zoneLightsData;
        let { zoneBData } = zoneLightsData;
        const zoneData = zoneBData;
        zoneData.map(async (deleteZone) => {
          await ConfigService.deleteConfigLights({ lightId: deleteZone.lightId });
        });
        zoneBData = JSON.parse(JSON.stringify(zoneDData));
        const data = zoneBData.map(async (zoneMainData) => {
          const object = zoneMainData;
          // set mirror obstruct data
          if (object.obstruct.length > 0) {
            object.obstruct = this.setMirrorObstructData("obstruct", ...object.obstruct);
          }
          // set mirror rotation data
          if (object.rotation !== "None") {
            object.rotation = this.setMirrorRotationData(object.rotation);
          }
          const request = {
            configId: selectedConfig && selectedConfig?.Id ? selectedConfig?.Id : selectedConfig?.id,
            zone: "B",
            level: "single",
            productId: zoneMainData.id,
            qty: zoneMainData.qty || zoneMainData.quantity,
            colors: zoneMainData.colors,
            obstruct: zoneMainData.obstruct,
            rotation: zoneMainData.rotation,
            lowPower: zoneMainData.lowPower,
            lightType: zoneMainData.lightType,
          };
          await ConfigService.addLightsToConfiguration(request).then((response) => {
            if (response && response.data) {
              const newLights = response.data.params.Item[0];
              object.lightId = newLights.id;
              object.zone = "B";
            }
          });
          return object;
        });
        Promise.all(data).then((results) => {
          mainData.zoneBData = results;
          this.setState({ zoneLightsData: mainData });
        });
        zoneLightsData.zoneBData = JSON.parse(JSON.stringify(zoneBData));
        const mirrorsData = {
          isMirrorToZoneB: !isMirrorToZoneB,
          isMirrorToZoneD: false,
        };
        await ConfigService.setMirrorZoneDetailsToStorage(mirrorsData);
        this.setState({
          isMirrorToZoneB: !isMirrorToZoneB,
          isMirrorToZoneD: false,
          zoneLightsData: mainData,
          setBlockingLowPowerZoneB: setBlockingLowPowerZoneD,
          setBlockingIntensityZoneB: setBlockingIntensityZoneD,
          setCallingLowPowerZoneB: setCallingLowPowerZoneD,
          setCallingIntensityZoneB: setCallingIntensityZoneD,
          enableLowPowerZoneB: enableLowPowerZoneD,
        });
      }
    } else {
      const mirrorsData = {
        isMirrorToZoneB: false,
        isMirrorToZoneD: false,
      };
      await ConfigService.setMirrorZoneDetailsToStorage(mirrorsData);
      this.setState({
        isMirrorToZoneB: !isMirrorToZoneB,
        isMirrorToZoneD: false,
        enableLowPowerZoneB: enableLowPowerZoneD,
      });
    }
  };

  checkForInactiveLights = () => {
    const { zoneLightsData } = this.state;
    let isInactive = false;
    let isExcluded = false;
    // eslint-disable-next-line no-restricted-syntax
    for (const [, value] of Object.entries(zoneLightsData)) {
      // eslint-disable-next-line no-loop-func
      value.forEach((lgt) => {
        if (lgt.isActive === false || lgt.inactiveLightheads?.length) {
          isInactive = true;
        }
        if (lgt.excluded === true || lgt.excludedLightheads?.length) {
          isExcluded = true;
        }
      });
    }
    if (isInactive || isExcluded) {
      this.setState({ preCalModalShow: true });
    } else {
      this.performDirtyPageOperations();
    }
  };

  performDirtyPageOperations = () => {
    const { selectedConfig, loading, isDirty } = this.state;
    if (!selectedConfig && !loading) {
      this.setState({ loading: true }, () => this.saveConfiguration(true));
    }
    if (selectedConfig && !loading && isDirty) {
      this.setState({ loading: true }, () => this.updateConfiguration(selectedConfig, true));
    } else if (selectedConfig && !loading && !isDirty) {
      this.getPdfResultData(selectedConfig.id, false);
    }
  };

  getPdfResultData = (id, excludeInactive) => {
    this.setState({ loading: true, isDirty: false });
    const { excludedLightsInCalculation, excludedLightsDuoTrioInCalculation } = this.state;
    ZoneServices.getPerformCalculationResults(
      id,
      excludeInactive,
      excludedLightsInCalculation,
      excludedLightsDuoTrioInCalculation
    )
      .then((response) => {
        if (response && response.data) {
          // merge excluded data of lights into lights data
          const excludedLightsInZones = [];
          const excludedLightsDuoTrioInZones = [];
          const { excludeData } = response.data;
          const { excludeDataDuoTrio } = response.data;
          const { lightsData } = response.data;
          Object.keys(excludeData).forEach((excluded) => {
            if (excludeData[excluded].level === "single") {
              if (`Zone ${excludeData[excluded].location}` in lightsData) {
                lightsData[`Zone ${excludeData[excluded].location}`].push(excludeData[excluded]);
                excludedLightsInZones[`Zone ${excludeData[excluded].location}`] = true;
              }
            } else if (`Zone ${excludeData[excluded].location} ${excludeData[excluded].level}` in lightsData) {
              lightsData[`Zone ${excludeData[excluded].location} ${excludeData[excluded].level}`].push(
                excludeData[excluded]
              );
              excludedLightsInZones[`Zone ${excludeData[excluded].location} ${excludeData[excluded].level}`] = true;
            }
          });
          Object.keys(excludeDataDuoTrio).forEach((excludeDuoTrio) => {
            if (excludeDataDuoTrio[excludeDuoTrio].level === "single") {
              if (`Zone ${excludeDataDuoTrio[excludeDuoTrio].location}` in lightsData) {
                const zoneArea = lightsData[`Zone ${excludeDataDuoTrio[excludeDuoTrio].location}`];
                zoneArea.forEach((light, index) => {
                  if (
                    light.lightId &&
                    excludeDataDuoTrio[excludeDuoTrio].lightId &&
                    light.lightId === excludeDataDuoTrio[excludeDuoTrio].lightId
                  ) {
                    lightsData[`Zone ${excludeDataDuoTrio[excludeDuoTrio].location}`].splice(index, 1);
                    excludeDataDuoTrio[excludeDuoTrio].excludedColors.forEach((excludeColor) => {
                      const colorIndexKeys = Object.keys(excludeDataDuoTrio[excludeDuoTrio].colors);
                      const colorMatchIndex = Object.values(excludeDataDuoTrio[excludeDuoTrio].colors).findIndex(
                        (lightColor) => excludeColor.color === lightColor.color
                      );
                      if (colorMatchIndex >= 0) {
                        excludeDataDuoTrio[excludeDuoTrio].colors[colorIndexKeys[colorMatchIndex]].excluded = true;
                        delete excludeDataDuoTrio[excludeDuoTrio].excluded;
                        if (colorMatchIndex === 0) {
                          // if excluded color is on first index swipe it position with next index for PDF, so it will not stikeout zone values
                          [
                            excludeDataDuoTrio[excludeDuoTrio].colors["1"],
                            excludeDataDuoTrio[excludeDuoTrio].colors["2"],
                          ] = [
                            excludeDataDuoTrio[excludeDuoTrio].colors["2"],
                            excludeDataDuoTrio[excludeDuoTrio].colors["1"],
                          ];
                        }
                      }
                    });
                  }
                });
                lightsData[`Zone ${excludeDataDuoTrio[excludeDuoTrio].location}`].push(
                  excludeDataDuoTrio[excludeDuoTrio]
                );
                excludedLightsDuoTrioInZones[`Zone ${excludeDataDuoTrio[excludeDuoTrio].location}`] = true;
              }
            } else if (
              `Zone ${excludeDataDuoTrio[excludeDuoTrio].location} ${excludeDataDuoTrio[excludeDuoTrio].level}` in
              lightsData
            ) {
              const zoneArea =
                lightsData[
                  `Zone ${excludeDataDuoTrio[excludeDuoTrio].location} ${excludeDataDuoTrio[excludeDuoTrio].level}`
                ];
              zoneArea.forEach((light, index) => {
                if (
                  light?.lightId &&
                  excludeDataDuoTrio[excludeDuoTrio].lightId &&
                  light?.lightId === excludeDataDuoTrio[excludeDuoTrio].lightId
                ) {
                  lightsData[
                    `Zone ${excludeDataDuoTrio[excludeDuoTrio].location} ${excludeDataDuoTrio[excludeDuoTrio].level}`
                  ].splice(index, 1);
                  excludeDataDuoTrio[excludeDuoTrio].excludedColors.forEach((excludeColor) => {
                    const colorIndexKeys = Object.keys(excludeDataDuoTrio[excludeDuoTrio].colors);
                    const colorMatchIndex = Object.values(excludeDataDuoTrio[excludeDuoTrio].colors).findIndex(
                      (lightColor) => excludeColor.color === lightColor.color
                    );
                    if (colorMatchIndex >= 0) {
                      excludeDataDuoTrio[excludeDuoTrio].colors[colorIndexKeys[colorMatchIndex]].excluded = true;
                      delete excludeDataDuoTrio[excludeDuoTrio].excluded;
                    }
                    if (colorMatchIndex === 0) {
                      // if excluded color is on first index swipe it position with next index for PDF, so it will not stikeout zone values
                      [excludeDataDuoTrio[excludeDuoTrio].colors["1"], excludeDataDuoTrio[excludeDuoTrio].colors["2"]] =
                        [
                          excludeDataDuoTrio[excludeDuoTrio].colors["2"],
                          excludeDataDuoTrio[excludeDuoTrio].colors["1"],
                        ];
                    }
                  });
                }
              });
              lightsData[
                `Zone ${excludeDataDuoTrio[excludeDuoTrio].location} ${excludeDataDuoTrio[excludeDuoTrio].level}`
              ].push(excludeDataDuoTrio[excludeDuoTrio]);
              excludedLightsDuoTrioInZones[
                `Zone ${excludeDataDuoTrio[excludeDuoTrio].location} ${excludeDataDuoTrio[excludeDuoTrio].level}`
              ] = true;
            }
          });

          const responseData = response.data;
          responseData.excludedLightsInZones = excludedLightsInZones;
          responseData.excludedLightsDuoTrioInZones = excludedLightsDuoTrioInZones;
          // re strucure lights data to add entry for colors as individual lights.
          const tempLight = {};
          Object.keys(lightsData).forEach((zone) => {
            lightsData[zone].forEach((light) => {
              Object.keys(light?.colors).forEach((lightColor, index) => {
                const colorsObject = { ...light, ...light?.colors[lightColor], displayQty: true };
                if (tempLight[zone]) {
                  if (index > 0) {
                    colorsObject.displayQty = false; // Show quantity for the first light of a duo or trio in the PDF.
                  }
                  tempLight[zone].push(colorsObject);
                } else {
                  tempLight[zone] = [colorsObject];
                }
              });
            });
          });
          const lightsData1 = { ...responseData.lightsData, ...tempLight };
          responseData.lightsData = lightsData1;
          if (responseData?.warningData?.length) {
            this.setState({
              performCalculationData: responseData,
              loading: false,
              prePdfCal: false,
              isResultAvailable: true,
              showCalculationWarning: true,
            });
          } else {
            this.setState({
              performCalculationData: responseData,
              loading: false,
              isResultAvailable: true,
            });
          }
        }
      })
      .catch((error) => {
        this.setState({ performCalculationData: null, loading: false });
        Helpers.displayErrorMessage(error);
      });
  };

  saveDataAndPerformCalculation = (excludedLightIds, excludedLightDuoTrio) => {
    this.setState(
      {
        preCalIndividualLightSelModalShow: false,
        excludedLightsInCalculation: excludedLightIds,
        excludedLightsDuoTrioInCalculation: excludedLightDuoTrio,
      },
      () => {
        ConfigService.setPerformPrePdfOperations(true);
      }
    );
  };

  closeLightSelectionModal = () => {
    this.setState({ preCalIndividualLightSelModalShow: false });
  };

  closePerformCalculationResult = () => {
    this.setState({ performCalculationData: null, isResultAvailable: false });
  };

  closeCalculationWarningModal = (isChecked) => {
    ConfigService.setWarningMessageStatus(isChecked);
    this.setState({ showCalculationWarning: false, isResultAvailable: true });
  };

  setDirty = (status) => {
    this.setState({ isDirty: status });
  };

  selectNextAction = (request) => {
    const { openConfigReq } = this.state;

    switch (request) {
      case "open":
        this.openConfigurationFromList(openConfigReq);
        break;
      case "new":
        this.navigateToHomepage();
        break;
      case "logout":
        this.setState({ logoutReq: true });
        break;
      default:
        break;
    }
  };

  closeChangesWarningModal = (data) => {
    const { changeRequest } = this.state;
    const action = changeRequest;
    this.setState({ changeRequest: "" });
    if (data) {
      const { selectedConfig } = this.state;
      if (selectedConfig) {
        this.setState({ loading: true }, () => this.updateConfiguration(selectedConfig, false, action));
      }
      if (!selectedConfig) {
        this.setState({ loading: true }, () => this.saveConfiguration(false, action));
      }
    } else if (data === false) {
      this.selectNextAction(action);
    }
  };

  continueCalculation = (data) => {
    const { selectedConfig, loading, isDirty } = this.state;
    this.setState({ preCalModalShow: false });
    if (!selectedConfig && !loading) {
      this.setState({ loading: true }, () => this.saveConfiguration(true, "", data));
    }
    if (selectedConfig && !loading && isDirty) {
      this.setState({ loading: true }, () => this.updateConfiguration(selectedConfig, true, "", data));
    } else if (selectedConfig && !loading && !isDirty) {
      if (data === true) {
        this.getPdfResultData(selectedConfig.id, true);
      } else if (data === false) {
        this.getPdfResultData(selectedConfig.id, false);
      }
    }
  };

  continueToIndividualLightSelection = () => {
    this.setState({ preCalModalShow: false });
    this.setState({ preCalIndividualLightSelModalShow: true });
  };

  closeIndividualLightSelection = () => {
    this.setState({ preCalIndividualLightSelModalShow: false });
  };

  getPreCalWarningPopup = () => {
    const { preCalModalShow } = this.state;
    return (
      <Modal
        className="preCal-modal"
        backdrop="static"
        tabIndex="-1"
        show={preCalModalShow}
        onHide={this.continueCalculation}
      >
        <PreCalculationWrngModal continueCalculation={this.continueCalculation} />
      </Modal>
    );
  };

  showPreCalIndividualLightSelModal = () => {
    this.setState({ preCalIndividualLightSelModalShow: true });
  };

  getPreCalLightsSelectionModal = () => {
    const { preCalIndividualLightSelModalShow, selectedConfig, zoneLightsData, isGreaterThan, standardName } =
      this.state;
    return (
      <Modal
        className="preCal-modal"
        backdrop="static"
        tabIndex="-1"
        show={preCalIndividualLightSelModalShow}
        onHide={this.continueCalculation}
      >
        <PreCalIndividualLightSelectionModal
          selectedConfig={selectedConfig}
          zoneLightsData={zoneLightsData}
          continueCalculation={this.continueCalculation}
          isGreaterThan={isGreaterThan}
          saveDataAndPerformCalculation={this.saveDataAndPerformCalculation}
          closeLightSelectionModal={this.closeLightSelectionModal}
          standardName={standardName}
        />
      </Modal>
    );
  };

  logout = () => <Logout />;

  getDirtyPageWarningPopup = () => {
    const { changeRequest } = this.state;
    return (
      <Modal
        className="saveasModal"
        backdrop="static"
        tabIndex="-1"
        show={changeRequest}
        onHide={this.closeChangesWarningModal}
      >
        <ChangesWarningModal
          closeChangesWarningModal={this.closeChangesWarningModal}
          message={INFO_MESSAGE.UNSAVED_CHANGES_CONFM}
        />
      </Modal>
    );
  };

  setPrintWarning = (print) => {
    this.setState({ printWarning: print }, () => {});
  };

  getCalculationWarningPopUp = () => {
    const { showCalculationWarning, performCalculationData, standardName } = this.state;
    return (
      <Modal
        className="rotation-modal"
        backdrop="static"
        tabIndex="-1"
        show={showCalculationWarning}
        onHide={this.closeCalculationWarningModal}
      >
        <CalculationWrngModal
          closeWarningModal={this.closeCalculationWarningModal}
          warningData={performCalculationData.warningData}
          standard={Helpers.getStdLabel(standardName)}
          printWarning={this.setPrintWarning}
        />
      </Modal>
    );
  };

  getModel = () => {
    const {
      systemType,
      jobName,
      departmentName,
      vehicleName,
      vinName,
      contractName,
      showConfigDetails,
      note,
      loading,
      selectedConfig,
    } = this.state;
    return (
      <Modal
        className="configdetail-modal"
        backdrop="static"
        tabIndex="-1"
        show={showConfigDetails}
        onHide={this.closeConfigDetailPopup}
      >
        <ConfigDetails
          closeConfigDetailPopup={this.closeConfigDetailPopup}
          systemType={systemType}
          systemTypes={systemTypes}
          jobName={jobName}
          departmentName={departmentName}
          vehicleName={vehicleName}
          vinName={vinName}
          contractName={contractName}
          note={note}
          loading={loading}
          selectedConfig={selectedConfig}
        />
      </Modal>
    );
  };

  getSaveAsModel = () => {
    const { isSaveAsModal, loading } = this.state;
    return (
      <Modal
        className="saveasModal"
        backdrop="static"
        tabIndex="-1"
        show={isSaveAsModal}
        onHide={this.closeSaveAsModal}
      >
        <ZoneSaveAsPage
          closeSaveAsModal={this.closeSaveAsModal}
          saveAsConfigData={this.saveAsConfiguration}
          loading={loading}
        />
      </Modal>
    );
  };

  // To open delete configuration modal
  getDeleteConfigModal = () => {
    const { isDeleteModal, loading, configName } = this.state;
    return (
      <Modal
        className="saveasModal"
        backdrop="static"
        tabIndex="-1"
        show={isDeleteModal}
        onHide={this.closeDelConfigModal}
      >
        <DeleteConfiguration
          closeDelConfigModal={this.closeDelConfigModal}
          delConfigData={this.deleteUserConfig}
          loading={loading}
          configName={configName}
          userConfigId={ConfigService?.getConfigDetailsFromStorage()}
        />
      </Modal>
    );
  };

  // To open share configuration modal
  getShareConfigModal = () => {
    const { isShareConfigModal, loading } = this.state;
    return (
      <Modal
        className="saveasModal"
        backdrop="static"
        tabIndex="-1"
        show={isShareConfigModal}
        onHide={this.closeShareConfigModal}
      >
        <ZoneShareConfiguration
          closeShareConfigModal={this.closeShareConfigModal}
          shareConfigData={this.shareConfiguration}
          loading={loading}
        />
      </Modal>
    );
  };

  getConfigListModel = () => {
    const { isConfigListModal } = this.state;
    return (
      <Modal
        className="config-list-modal"
        backdrop="static"
        tabIndex="-1"
        show={isConfigListModal}
        onHide={this.closeConfigListModal}
      >
        <ConfigListModal closeConfigListModal={this.closeConfigListModal} />
      </Modal>
    );
  };

  render() {
    const {
      isGreaterThan,
      zoneLightsData,
      configName,
      systemType,
      jobName,
      departmentName,
      vehicleName,
      vinName,
      contractName,
      lightsData,
      mySources,
      commonlyUsed,
      standardName,
      ownerEmail,
      isMirrorToZoneB,
      isMirrorToZoneD,
      loading,
      performCalculationData,
      isResultAvailable,
      showConfigDetails,
      isSaveAsModal,
      isShareConfigModal,
      isConfigListModal,
      showCalculationWarning,
      changeRequest,
      logoutReq,
      preCalModalShow,
      preCalIndividualLightSelModalShow,
      printWarning,
      isDeleteModal,
      lowPowerOptions,
      setBlockingLowPowerZoneA,
      enableLowPowerZoneA,
      setBlockingIntensityZoneA,
      setBlockingLowPowerZoneB,
      enableLowPowerZoneB,
      setBlockingIntensityZoneB,
      setBlockingLowPowerZoneC,
      enableLowPowerZoneC,
      setBlockingIntensityZoneC,
      setBlockingLowPowerZoneD,
      enableLowPowerZoneD,
      setBlockingIntensityZoneD,
      setCallingLowPowerZoneA,
      setCallingLowPowerZoneB,
      setCallingLowPowerZoneC,
      setCallingLowPowerZoneD,
      setCallingIntensityZoneA,
      setCallingIntensityZoneB,
      setCallingIntensityZoneC,
      setCallingIntensityZoneD,
    } = this.state;
    const { history } = this.props;

    const isOntario = standardName === "Ontario_5.0" || standardName === ONTARIO.VALUE;
    return (
      <>
        {loading && !isSaveAsModal && !showConfigDetails && !isShareConfigModal && !isDeleteModal ? (
          <div className="fullloader m-auto" />
        ) : null}
        {showConfigDetails ? this.getModel() : null}
        {isSaveAsModal ? this.getSaveAsModel() : null}
        {isShareConfigModal ? this.getShareConfigModal() : null}
        {isDeleteModal ? this.getDeleteConfigModal() : null}
        {isConfigListModal ? this.getConfigListModel() : null}
        {showCalculationWarning ? this.getCalculationWarningPopUp() : null}
        {changeRequest ? this.getDirtyPageWarningPopup() : null}
        {logoutReq ? this.logout() : null}
        {preCalModalShow ? this.getPreCalWarningPopup() : null}
        {preCalIndividualLightSelModalShow ? this.getPreCalLightsSelectionModal() : null}
        <main className={`zone-page ${loading && !isSaveAsModal && !showConfigDetails ? "loadingOpacity" : ""}`}>
          <DragDropContext onDragEnd={this.onDragEnd}>
            <div className={`${isResultAvailable ? "cursor-na" : ""} gw-flex-zone`}>
              <div className={`content theme-bg ${isResultAvailable ? "disable-zone" : ""}`}>
                <div className="zone-block">
                  <div className="row zone-header d-flex align-items-center justify-content-between">
                    <div className="form-group d-flex align-items-center align-content-start">
                      <div className="brand-logo">
                        <img alt="" src={WhelenRImg} className="img-fluid" />
                      </div>
                      <div className="zone-title">
                        <h3 className="text-white text-uppercase mb-0">APPARATUS OUTFITTING GUIDE</h3>
                      </div>
                    </div>
                    {ownerEmail ? (
                      <div className="form-group d-flex align-items-center zone-page-owner">
                        <label htmlFor="name" className="mb-0">
                          Owner
                        </label>
                        <span
                          type="text"
                          className="form-control text-eclips ml-4 header-input"
                          id="owner"
                          data-tip={ownerEmail}
                          data-for="owner"
                        >
                          {ownerEmail}
                        </span>
                        <ReactTooltip
                          id="owner"
                          place="right"
                          multiline
                          type="light"
                          effect="solid"
                          className="zone-tooltip"
                        />
                      </div>
                    ) : null}
                    <div className="form-group d-flex align-items-center zone-page-name">
                      <label htmlFor="name" className="mb-0">
                        Name
                      </label>
                      <input
                        type="text"
                        className="form-control text-eclips ml-4 header-input"
                        id="name"
                        onChange={(e) => {
                          this.setConfigName(e.target.value);
                        }}
                        value={configName}
                        autoComplete="off"
                        maxLength={40}
                        data-tip={configName}
                        data-for="name1"
                      />
                      <ReactTooltip
                        id="name1"
                        place="right"
                        multiline
                        type="light"
                        effect="solid"
                        className="zone-tooltip"
                      />
                    </div>
                  </div>
                  <ZoneContent
                    isGreaterThan={isGreaterThan}
                    onDragEnd={this.onDragEnd}
                    zoneLightsData={zoneLightsData}
                    deleteLightsFromZone={this.deleteLightsFromZone}
                    setKeysData={this.setKeysData}
                    configName={configName}
                    setConfigName={this.setConfigName}
                    changeSystemTypes={this.changeSystemTypes}
                    systemType={systemType}
                    systemTypes={systemTypes}
                    jobName={jobName}
                    setJobName={this.setJobName}
                    setDepartmentName={this.setDepartmentName}
                    departmentName={departmentName}
                    vehicleName={vehicleName}
                    vinName={vinName}
                    isOntario={isOntario}
                    setVehicleName={this.setVehicleName}
                    setVinName={this.setVinName}
                    setContractName={this.setContractName}
                    contractName={contractName}
                    mirrorToZoneB={this.mirrorToZoneB}
                    mirrorToZoneD={this.mirrorToZoneD}
                    isMirrorToZoneB={isMirrorToZoneB}
                    isMirrorToZoneD={isMirrorToZoneD}
                    // performCalculation={this.saveDataAndPerformCalculation}
                    performCalculation={this.showPreCalIndividualLightSelModal}
                    isResultAvailable={isResultAvailable}
                    setDirty={this.setDirty}
                    standardName={standardName}
                    onSetLowPower={this.onSetLowPower}
                    lowPowerOptions={lowPowerOptions}
                    setBlockingLowPowerZoneA={setBlockingLowPowerZoneA}
                    enableLowPowerZoneA={enableLowPowerZoneA}
                    setBlockingIntensityZoneA={setBlockingIntensityZoneA}
                    setBlockingLowPowerZoneB={setBlockingLowPowerZoneB}
                    enableLowPowerZoneB={enableLowPowerZoneB}
                    setBlockingIntensityZoneB={setBlockingIntensityZoneB}
                    setBlockingLowPowerZoneC={setBlockingLowPowerZoneC}
                    enableLowPowerZoneC={enableLowPowerZoneC}
                    setBlockingIntensityZoneC={setBlockingIntensityZoneC}
                    setBlockingLowPowerZoneD={setBlockingLowPowerZoneD}
                    enableLowPowerZoneD={enableLowPowerZoneD}
                    setBlockingIntensityZoneD={setBlockingIntensityZoneD}
                    setCallingLowPowerZoneA={setCallingLowPowerZoneA}
                    setCallingIntensityZoneA={setCallingIntensityZoneA}
                    setCallingLowPowerZoneB={setCallingLowPowerZoneB}
                    setCallingIntensityZoneB={setCallingIntensityZoneB}
                    setCallingLowPowerZoneC={setCallingLowPowerZoneC}
                    setCallingIntensityZoneC={setCallingIntensityZoneC}
                    setCallingLowPowerZoneD={setCallingLowPowerZoneD}
                    setCallingIntensityZoneD={setCallingIntensityZoneD}
                    onChangeZoneIntensity={this.onChangeZoneIntensity}
                  />
                </div>
              </div>
            </div>
            <ZoneSidebarPage
              setMySources={this.setMySources}
              onDragEnd={this.onDragEnd}
              lightsData={lightsData || []}
              history={history}
              mySources={mySources}
              commonlyUsed={commonlyUsed}
              deleteLightsFromPreference={this.deleteLightsFromPreference}
              deleteFromMysources={this.deleteFromMysources}
              performCalculationData={performCalculationData}
              isResultAvailable={isResultAvailable}
              closePerformCalculationResult={this.closePerformCalculationResult}
              printWarning={printWarning}
            />
          </DragDropContext>
        </main>
      </>
    );
  }
}

export default withRouter(ZonePage);

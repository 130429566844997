import { Color3, Color4, Vector3 } from "@babylonjs/core";

export const LightbarPreviewConstant = {
  SceneColor: new Color4(0, 0, 0, 0),
  CameraPosition: new Vector3(2.8, 0.75, 0),
  CameraAlpha: Math.PI / 2,
  CameraBeta: Math.PI / 2,
  CameraRadius1: 4,
  CameraRadius2: 1.8,
  CameraDirection: new Vector3(0, 0, 0),
  LightDirection: new Vector3(5, 1, 25),
  InitialBoxPosition1: new Vector3(5.25, 2.1, 0),
  InitialBoxPosition2: new Vector3(5.25, 1.1, 0),
  InitialBoxPosition3: new Vector3(5.25, 1.4, 0),
  InitialBoxRotationAngle: 90,
  LightbarWidth: 500,
  LightbarHeight: 200,
  MinHW: 10,
  BoxHeight: 0.01,
  RedColor: new Color3.Red(),
  BlackColor: new Color3.Black(),
  PerspectiveRatio1: 0.8,
  PerspectiveRatio2: 1.5,
  PerspectiveRatio3: 1.7,
};

export const LIGHT_COLORS = {
  0: new Color3.Teal(), // None. Non-Assignable
  1: new Color3(0.88, 0.65, 0), // Amber. Default TA color. Assignable
  2: new Color3.Blue(), // Blue. Assignable
  4: new Color3.Teal(), // Clear. Not assignable
  8: new Color3.Green(), // Green. Assignable
  16: new Color3.Red(), // Red. Assignable
  32: new Color3.White(), // White. Assignable
  // Output color reference control assignable only
  33: new Color3.Teal(), // 'BlueAmber'
  34: new Color3.Teal(), // 'BlueWhite'
  35: new Color3.Teal(), // 'RedAmber'
  36: new Color3.Teal(), // 'RedBlue'
  37: new Color3.Teal(), // 'RedGreen'
  38: new Color3.Teal(), // 'RedWhite'
  41: new Color3.Teal(),
  40: new Color3.Teal(),
  customWhite: new Color3(0.35, 0.35, 0.35),
};

export const SPECS_1900 = {
  CH10: "NFPA_1900_Ch.10",
  CH31: "NFPA_1900_Ch.31",
  ULC515: "CAN_ULC_515_2024",
};

export const VEHICLESIZE_1900 = {
  GREATER_THAN: "Length of 25' Greater or Device Mounted 8.5' or higher than ground level",
  LESS_THAN: "Length less than 25' or Device Mounted 8.5' or lower than ground",
  GREATER_THAN_7M: "Greater than 7.62 meters",
  LESS_THAN_7M: "Less than 7.62 meters",
};

export const OP_SYSTEM = {
  MAC: "Mac",
  WINDOWS: "Windows",
  IOS: "IOS",
  LINUX: "Linux",
};

export const ONTARIO = {
  NAME: "OPLA & ERV 6.1",
  VALUE: "Ontario_6.1",
};

export const STANDARD = {
  ULC515_2024: "CAN/ULC 515:2024",
};

export const PRIMARY_EMERGENCY = "primary emergency";

export const SIZES = {
  GREATER_THAN: "GREATER_THAN_25",
  LESS_THAN: "LESS_THAN_25",
  ANY_SIZE: "ANY_SIZE",
};

export const LIGHT_TYPES = {
  IMPORTEDLIGHTBAR: "IMPORTED LIGHTBAR",
};

export const LOW_STRING = "Low";
export const LOW_PERCENTAGE = 25;
export const ONTARIO_STANDARD = ["Ontario_5.0", "Ontario_6.1"];
export const BLOCKING_LOWPOWER_TOOLTIP = "Set the Blocking Intensity to Low Power for all supported devices";
export const CALLING_LOWPOWER_TOOLTIP = "Set the Calling Intensity to Low Power for all supported devices";
export const DELETE_MSG = "Are you sure you want to delete  ";
export const ZONE = {
  ZONE_A: "A",
  ZONE_B: "B",
  ZONE_C: "C",
  ZONE_D: "D",
};
export const DEFAULT_LOW_POWER = { label: "25%", value: 25 };
export const CALLING_MODE = "Calling";
export const BLOCKING_MODE = "Blocking";
